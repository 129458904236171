import { Hardcode } from '../_helpers/hardcode-handle';
import { Injectable, OnDestroy } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { ServerUrl, AppErrorHandler, AppConfig, Constants } from '../_helpers';
import { DataService } from '../_helpers/data-service-handle';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from './common.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RxStomp } from '@stomp/rx-stomp'

@Injectable()
export class IgeService implements OnDestroy {

  private gameListObserver = new Subject<any>();
  private purchaseTicketResponse: any;

  static gameTime = 0;
  static gameTimeInterval: any;
  static fetchGameCounter = 0;
  isMobile = false;

  orderingTicketList = {
    gameCode: '',
    orderBy: ''
  };
  fromMyTickets = false;
  rxStomp: any;
  private subjectSocketData = new Subject<any>();
  rxJsSubscription: any;

  constructor(
    private dataservice: DataService,
    private alertService: AlertService,
    private commonService: CommonService,
    private deviceService: DeviceDetectorService) {
    this.isMobile = (this.deviceService.isMobile() || this.deviceService.isTablet()) ? true : false;
  }

  ngOnDestroy(): void {
    clearInterval(IgeService.gameTimeInterval);
    if (typeof this.rxJsSubscription != 'undefined') {
      this.rxJsSubscription.unsubscribe();
    }
  }

  subscribeGameList(): Observable<any> {
    return this.gameListObserver.asObservable();
  }

  subscribeSocketData(): Observable<any> {
    return this.subjectSocketData.asObservable();
  }

  getGameList = (timerType = '') => {
    let response = JSON.parse(localStorage.getItem('igeGameData'));
    if (response == null || IgeService.fetchGameCounter == 0) {
      this.returnSLEDrawDataResponse(timerType).subscribe(response => {
        this.gameListObserver.next(response);
      });
    } else {
      // var reset = false;
      // for (var i = 0; i < response.data.sleData.gameData[0].gameTypeData.length; i++) {
      //   var element = response.data.sleData.gameData[0].gameTypeData[i];
      //   if (element.drawData != 0) {
      //     let drawFreezeTime = this.changeDateFormat(response.data.sleData.gameData[0].gameTypeData[i].drawData[0].ftg);
      //     let gameDate = new Date(drawFreezeTime);
      //     let diff = Math.abs(Math.abs(gameDate.getTime() - IgeService.gameTime) / 1000);
      //     if (diff <= 0) {
      //       this.returnSLEDrawDataResponse(timerType).subscribe(response => {
      //         this.gameListObserver.next(response);
      //         reset = true;
      //       });
      //       break;
      //     }
      //   }
      // }
      // if (reset == false) {
      //   response.timerType = timerType;
      //   this.gameListObserver.next(response);
      // }
    }
  }

  returnSLEDrawDataResponse(timerType = '') {
    return this.dataservice.postData(ServerUrl.IGE_GAMELIST, { service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        if (typeof response.data != 'undefined') {
          localStorage.setItem('igeGameData', JSON.stringify(response));
          return response;
        } else {
          if (response.responseCode == 1000) {
            return response;
          } else {
            this.alertService.error(response.responseMessage);
          }
        }
      }));
  }

  connectSocket(alias) {
    this.rxStomp = new RxStomp();
    this.rxStomp.configure({
      brokerURL: ServerUrl.IGE_WEBSOCKET_URL,
      connectHeaders: {},
      heartbeatIncoming: 0,
      heartbeatOutgoing: 20000,
      reconnectDelay: 200,
      // debug: (msg: string): void => {
      //   console.log(new Date(), msg);
      // }
    });
    this.rxStomp.activate();
    this.rxJsSubscription = this.rxStomp.watch("/amounts/" + alias).pipe(map(function (message: any) {
      return JSON.parse(message.body);
    })).subscribe((payload) => {
      this.subjectSocketData.next(payload);
    });
  }

  publishSocketJackpot({ gameCode, alias, currencyCode }) {
    let stompObj = {
      "serviceCode": "IGE",
      "machineId": alias,
      "currencyCode": currencyCode,
      "level": "MACHINE"
    };
    if (gameCode != 0) {
      stompObj["gameCode"] = gameCode;
      this.rxStomp.publish({ destination: "/app/amounts", body: JSON.stringify(stompObj) });
    } else {
      this.rxStomp.publish({ destination: "/app/amounts/lobby", body: JSON.stringify(stompObj) });
    }
  }

  deactivateSocketJackpot() {
    this.rxStomp.deactivate();
  }

}