import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { ServerUrl } from '../_helpers';
import { DataService } from '../_helpers/data-service-handle';

@Injectable()
export class ScratchService {
  constructor(
        private dataservice: DataService,
        private alertService: AlertService) { }

  bookActivate(booknumber: any) {
    return this.dataservice.postData(ServerUrl.SCRATCH_ACTIVATE_BOOK, { booknumber: booknumber }, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  getGameList() {
    return this.dataservice.postData(ServerUrl.SCRATCH_GET_GAMELIST, {}, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {        
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  receiveBook(challanNumber:string,bookList: any) {
    return this.dataservice.postData(ServerUrl.SCRATCH_RECEIVE_BOOK, { challanNumber:challanNumber,bookList: bookList }, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  quickOrder(gameOrderList) {
    return this.dataservice.postData(ServerUrl.SCRATCH_QUICK_ORDER, {gameOrderList:gameOrderList}, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  // verifyTicket(ticketnumber,virnNumber) {
    verifyTicket(ticketnumber) {
    // return this.dataservice.postData(ServerUrl.SCRATCH_VERIFY_TICKET, { ticketnumber:ticketnumber,virnnumber:virnNumber }, { withCredentials: true })
    return this.dataservice.postData(ServerUrl.SCRATCH_VERIFY_TICKET, { barcodeNumber:ticketnumber}, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  // claimWin(ticketNumber,virnNumber) {
    claimWin(ticketNumber) {
    // return this.dataservice.postData(ServerUrl.SCRATCH_WINNING_CLAIM, {ticketNumber:ticketNumber,virnnumber:virnNumber}, { withCredentials: true })
    return this.dataservice.postData(ServerUrl.SCRATCH_WINNING_CLAIM, {ticketNumber:ticketNumber}, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  sale(ticketnumber: any) {
    return this.dataservice.postData(ServerUrl.SCRATCH_SALE_TICKET, { ticketNumberList: [ticketnumber] }, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  getDLDetails(challanNumber: any) {
    return this.dataservice.postData(ServerUrl.SCRATCH_DL_DETAILS, { challanNumber: challanNumber }, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  getReturnNote(challanNumber: any) {
    return this.dataservice.postData(ServerUrl.SCRATCH_RETURN_NOTE, { challanNumber: challanNumber }, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  getGameWiseInventory() {
    return this.dataservice.postData(ServerUrl.SCRATCH_GAME_WISE_INVENTORY, {}, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  returnBook(challanNumber:string, packsToReturn: any) {
    return this.dataservice.postData(ServerUrl.SCRATCH_RETURN_PACK, {'dlChallanNumber': challanNumber, 'packsToReturn': packsToReturn}, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  getInventoryDetailsForRetailer() {
    return this.dataservice.postData(ServerUrl.SCRATCH_RETAILER_WISE_INVENTORY, {}, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }
}