import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../_services';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['../css/common.css']
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  private subscription: Subscription;
  constructor(private loaderService: LoaderService, private renderer: Renderer2) { }
  ngOnInit() {
    this.subscription = this.loaderService.getMessage().subscribe((state: any) => {
      this.show = state.show;
      // if(this.show){
      //   this.renderer.addClass(document.body, 'modal-open');
      // }else{
      //   this.renderer.removeClass(document.body, 'modal-open');
      // };
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
