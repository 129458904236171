import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next({ type: '', text: '' });
                }
            }
        });
    }

    success(message: string, keepAfterNavigationChange = false, heading?: string) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message, heading: heading });
    }

    successBuy(message: string, url = "", ticketNumber = "", keepAfterNavigationChange = false, heading?: string) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message, url: url, ticketNumber: ticketNumber, heading: heading });
    }

    error(message: string, keepAfterNavigationChange = false, heading?: string) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message, heading: heading });
    }

    errorParentSbs(message: string, url = "", keepAfterNavigationChange = false, heading?: string) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message, url: url, redirectParent: 'loadSBS', heading: heading });
    }

    warning(message: string, keepAfterNavigationChange = false, heading?: string) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'warning', text: message, heading: heading });
    }

    info(message: string, keepAfterNavigationChange = false, heading?: string) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'info', text: message, heading: heading });
    }

    question(message: string, keepAfterNavigationChange = false, heading?: string, callbackYes?: Function, callbackYesParams?: Array<any>, callbackNo?: Function, callbackNoParams?: Array<any>) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'question', text: message, heading: heading, callbackYes: callbackYes, callbackYesParams: callbackYesParams, callbackNo: callbackNo, callbackNoParams: callbackNoParams });
    }

    clear(keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'clear', text: '' });
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}