import { environment } from 'src/environments/environment';

export class ServerUrl {

    static readonly RMS_LOGIN = `${environment[environment.client].domain}/rms/login`;
    static readonly RMS_LOGOUT = `${environment[environment.client].domain}/rms/logout`;
    static readonly RMS_USER_INFO = `${environment[environment.client].domain}/rms/getuserdata`;

    static readonly IFRAME_USER_INT = `${environment[environment.client].domain}/player/initial`;

    static readonly SCRATCH_ACTIVATE_BOOK = `${environment[environment.client].domain}/scratch/activatebook`;
    static readonly SCRATCH_GET_GAMELIST = `${environment[environment.client].domain}/scratch/getgamelist`;
    static readonly SCRATCH_RECEIVE_BOOK = `${environment[environment.client].domain}/scratch/receivebook`;
    static readonly SCRATCH_DL_DETAILS = `${environment[environment.client].domain}/scratch/dldetails`;
    static readonly SCRATCH_QUICK_ORDER = `${environment[environment.client].domain}/scratch/quickorder`;
    static readonly SCRATCH_VERIFY_TICKET = `${environment[environment.client].domain}/scratch/verifyticket`;
    static readonly SCRATCH_WINNING_CLAIM = `${environment[environment.client].domain}/scratch/winningclaim`;
    static readonly SCRATCH_SALE_TICKET = `${environment[environment.client].domain}/scratch/saleticket`;
    static readonly SCRATCH_RETURN_NOTE = `${environment[environment.client].domain}/scratch/returnnote`;
    static readonly SCRATCH_RETURN_PACK = `${environment[environment.client].domain}/scratch/packreturn`;
    static readonly SCRATCH_GAME_WISE_INVENTORY = `${environment[environment.client].domain}/scratch/gameinventory`;
    static readonly SCRATCH_RETAILER_WISE_INVENTORY = `${environment[environment.client].domain}/scratch/inventory`;

    static readonly REPORTS_GET_LEDGER = `${environment[environment.client].domain}/reports/getledger`;
    static readonly REPORTS_GET_SUMMARIZED_LEDGER = `${environment[environment.client].domain}/reports/getSummarizedLedger`;
    static readonly REPORTS_GET_SALE_REPORT = `${environment[environment.client].domain}/reports/getsalereport`;
    static readonly REPORTS_GET_SERVICE_LIST = `${environment[environment.client].domain}/reports/getservicelist`;

    static readonly NOTIFY_DRAW_MACHINE = `${environment[environment.client].domain}/notifydrawmachine`;

    static readonly DRAW_GET_GAMEDATA = `${environment[environment.client].domain}/draw/fetchgamedata`;
    static readonly DRAW_GET_PRIZE_SCHEME = `${environment[environment.client].domain}/draw/fetchprizescheme`;
    static readonly DRAW_GET_QUICK_PICK = `${environment[environment.client].domain}/draw/quickpick`;
    static readonly DRAW_TICKET_SALE = `${environment[environment.client].domain}/draw/sale`;
    static readonly DRAW_VERIFY_TICKET = `${environment[environment.client].domain}/draw/verifyTicket`;
    static readonly DRAW_CLAIM_WIN = `${environment[environment.client].domain}/draw/claimWin`;
    static readonly DRAW_TICKET_CANCEL = `${environment[environment.client].domain}/draw/cancelticket`;
    static readonly DRAW_TICKET_REPRINT = `${environment[environment.client].domain}/draw/reprint`;
    static readonly DRAW_GET_RESULTS = `${environment[environment.client].domain}/draw/result`;
    static readonly DRAW_TRACK_TICKET = `${environment[environment.client].domain}/draw/track`;
    static readonly DRAW_FETCH_TICKET_STATUS = `${environment[environment.client].domain}/draw/fetchstatus`;
    static readonly DRAW_FETCH_PLAYER_TICKET_LIST = `${environment[environment.client].domain}/draw/geticketdetails`;
    static readonly DRAW_FETCH_CARD_LIST = `${environment[environment.client].domain}/draw/getcarddetails`;
    static readonly DRAW_WINNER_LIST = `${environment[environment.client].domain}/draw/getwinnerlist`;
    static readonly DRAW_PANELWISE_RANK = `${environment[environment.client].domain}/draw/getpanelwiserank`;

    static readonly SLE_GET_MATCHLIST = `${environment[environment.client].domain}/sle/fetchmatchlist`;
    static readonly SLE_GET_SALE = `${environment[environment.client].domain}/sle/sale`;
    static readonly SLE_FETCHSTATUS = `${environment[environment.client].domain}/sle/fetchstatus`;
    static readonly SLE_GET_RESULT = `${environment[environment.client].domain}/sle/result`;
    static readonly SLE_FETCH_DRAWDATA = `${environment[environment.client].domain}/sle/fetchdrawdata`;
    static readonly SLE_FETCH_TICKETS = `${environment[environment.client].domain}/sle/playerTicketsInformation`;
    static readonly SLE_TRACK_TICKET = `${environment[environment.client].domain}/sle/purchaseTicketData`;
    static readonly SLE_POOL_UPDATE = `${environment[environment.client].domain}/sle/poolUpdate`;

    static readonly DRAW_TICKET_PRINT_PURCHASE = `${environment[environment.client].printDomain}/draw/purchase`;
    static readonly DRAW_TICKET_PRINT_CANCEL = `${environment[environment.client].printDomain}/draw/cancel`;
    static readonly DRAW_TICKET_PRINT_CLAIM_WIN = `${environment[environment.client].printDomain}/draw/claim`;
    static readonly DRAW_TICKET_PRINT_DUMMY = `${environment[environment.client].printDomain}/draw/dummy`;
    static readonly DRAW_TICKET_PRINT_RESULT = `${environment[environment.client].printDomain}/draw/result`;
    static readonly SLE_TICKET_PRINT_PURCHASE = `${environment[environment.client].printDomain}/sle/purchase`;

    static readonly DRAWMACHINE_LUCKYSIX = `${environment[environment.client].drawMachine_luckySix}`;
    static readonly DRAWMACHINE_SUPERKENO = `${environment[environment.client].drawMachine_superKeno}`;
    static readonly DRAWMACHINE_THAILOTTERY = `${environment[environment.client].drawMachine_thaiLottery}`;
    static readonly DRAWMACHINE_LOTTODIAMOND = `${environment[environment.client].drawMachine_lottoDiamond}`;
    static readonly DRAWMACHINE_FIVEBYNINETY = `${environment[environment.client].drawMachine_fiveByNinety}`;
    static readonly DRAWMACHINE_POWERPLAY = `${environment[environment.client].drawMachine_powerPlay}`;
    static readonly DRAWMACHINE_FORTUNE = `${environment[environment.client].drawMachine_fortune}`;
    static readonly DRAWMACHINE_POWERBALL = `${environment[environment.client].drawMachine_powerBall}`;
    static readonly DRAWMACHINE_BONUSLOTTO = `${environment[environment.client].drawMachine_bonusLotto}`;
    static readonly DRAWMACHINE_2D_MYANMAR = `${environment[environment.client].drawMachine_2d_myanmar}`;
    static readonly DRAWMACHINE_TWELVEBYTWENTYFOUR = `${environment[environment.client].drawMachine_twelvebytwentyfour}`;
    static readonly DRAWMACHINE_EIGHTBYTWENTYFOUR = `${environment[environment.client].drawMachine_eightbytwentyfour}`;
    static readonly DRAWMACHINE_ZOOLOTTO = `${environment[environment.client].drawMachine_bichorapido}`;
    static readonly DRAWMACHINE_LOTTOAMIGO = `${environment[environment.client].drawMachine_lottoamigo}`;

    static readonly WEBSOCKET_DOMAIN = `${environment[environment.client].dge_webSocket_domain}`;

    static readonly SOCKETIO_ENDPOINT = `${environment[environment.client].chat_socket}`;

    static readonly IGE_WEBSOCKET_URL = `${environment[environment.client].ige_socket_url}`;

    static readonly IGE_GAMELIST = `${environment[environment.client].domain}/ige/fetchmatchlist`;

    static readonly SGE_GAMELIST = `${environment[environment.client].domain}/sge/fetchmatchlist`;
    static readonly SGE_GAME_URL = `${environment[environment.client].domain}/sge/getgameurl`;

    static readonly SGE2_GAMELIST = `${environment[environment.client].domain}/sge2/fetchmatchlist`;
    static readonly SGE2_GAME_URL = `${environment[environment.client].domain}/sge2/getgameurl`;

    static readonly CAP_GAMELIST = `${environment[environment.client].domain}/cap/fetchmatchlist`;
    static readonly CAP_GAME_URL = `${environment[environment.client].domain}/cap/getgameurl`;
}