import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ResultService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router) {
        // clear alert message on route change
      router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          if (this.keepAfterNavigationChange) {
            // only keep for a single location change
            this.keepAfterNavigationChange = false;
          } else {
            // clear alert
            this.subject.next();
          }
        }
      });
    }

    success(message: boolean) {
        this.subject.next(message);
    }

    resultMessage(type: string, messageList: any, messageType: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: type, messageList: messageList, messageType: messageType  } );
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}