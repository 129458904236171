export class AppConfig {
    static readonly CLIENT_CONFIG = {
        'myanmar': {
            'maxTicketLength': 22,
            'packNumberEnable': true,
            'checkSumEnable': false,
            'vrnLength': 8,
            'checkSumLength': 0,
            'print_enable': false,
            'service': 'B2C',
            'iframe': true,
        },
        'cameroon': {
            'maxTicketLength': 22,
            'packNumberEnable': true,
            'checkSumEnable': false,
            'vrnLength': 8,
            'checkSumLength': 0,
            'print_enable': false,
            'service': 'B2C',
            'iframe': true,
        },
        'kenya': {
            'maxTicketLength': 22,
            'packNumberEnable': true,
            'checkSumEnable': false,
            'vrnLength': 8,
            'checkSumLength': 0,
            'print_enable': false,
            'service': 'B2C',
            'iframe': true,
        },
        'ice': {
            'maxTicketLength': 22,
            'packNumberEnable': true,
            'checkSumEnable': false,
            'vrnLength': 8,
            'checkSumLength': 0,
            'print_enable': false,
            'service': 'B2C',
            'iframe': true,
        },
        'arabia': {
            'maxTicketLength': 22,
            'packNumberEnable': true,
            'checkSumEnable': false,
            'vrnLength': 8,
            'checkSumLength': 0,
            'print_enable': false,
            'service': 'B2C',
            'iframe': true,
        },
        'sisal': {
            'maxTicketLength': 22,
            'packNumberEnable': false,
            'checkSumEnable': true,
            'vrnLength': 8,
            'checkSumLength': 2,
            'print_enable': true,
            'service': 'B2B',
            'iframe': false,
        },
        'guinee': {
            'maxTicketLength': 22,
            'packNumberEnable': true,
            'checkSumEnable': false,
            'vrnLength': 8,
            'checkSumLength': 0,
            'print_enable': false,
            'service': 'B2C',
            'iframe': true,
        },
    };
}