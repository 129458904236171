export const environment = {
  production: true,
  client: 'guinee',
  env: 'qa',
  myanmar: {
    gamesEnable: {
      dge: true,
      sle: true,
      bingo: true,
      big_boss: true,
      ige: true,
      sge: true,
      sge2: true,
      cap: true,
      default: 'dge',
    },
    theme: ['midnight-express-theme'],
    domain: 'https://asia-games.lottoweaver.com/api',
    printDomain: 'http://192.168.132.68:3000',
    drawMachine_luckySix: 'http://10.160.10.107:8088/LuckySix/web-mobile/',
    drawMachine_superKeno: 'http://10.160.10.107:8088/SuperKeno/web-mobile/',
    drawMachine_fiveByNinety: 'http://10.160.10.107:8088/Cameroon5by90/web-mobile/',
    drawMachine_lottoDiamond: 'http://10.160.10.107:8088/AndmanLottoDiamond/web-mobile/',
    drawMachine_powerPlay: 'http://10.160.10.107:8088/PowerPlay/web-mobile/',
    drawMachine_powerBall: 'http://10.160.10.107:8088/6by42Build2/web-mobile/',
    drawMachine_fortune: 'http://10.160.10.107:8088/Fortune/web-mobile/',
    drawMachine_bonusLotto: 'http://10.160.10.107:8088/BonusLotto/web-mobile/',
    dge_webSocket_domain: 'ws://10.160.10.100:8001',
    dge_webSocket_user: 'user',
    dge_webSocket_pswd: 'user',
    chat_socket: 'https://13.234.199.186:3234',
    ige_socket_url: 'wss://beta-ige.bet2winasia.com/JackpotEngine/socket',
  },
  cameroon: {
    gamesEnable: {
      dge: true,
      sle: true,
      big_boss: true,
      ige: true,
      sge: true,
      sge2: true,
      cap: true,
      default: 'dge',
    },
    theme: ['tropical-rain-forest-theme', 'style-cameroon'],
    domain: 'http://qa-games.camwinlotto.cm/api',
    printDomain: 'http://192.168.132.68:3000',
    drawMachine_luckySix: 'https://uat-dms.camwinlotto.cm/LuckySix/web-mobile/',
    drawMachine_superKeno: 'https://uat-dms.camwinlotto.cm/SuperKeno/web-mobile/',
    drawMachine_fiveByNinety: 'https://uat-dms.camwinlotto.cm/Cameroon5by90/web-mobile/',
  },
  kenya: {
    gamesEnable: {
      dge: true,
      ige: true,
      default: 'dge',
    },
    theme: ['midnight-express-theme'],
    // domain: 'http://games.sabanzuri.com/api',
    domain: 'http://portal-games.infinitilotto.com/api',
    printDomain: 'http://192.168.132.68:3000',
    drawMachine_powerPlay: 'http://10.160.10.30:8088/12by24Build/web-mobile/',
    drawMachine_powerBall: 'http://10.160.10.30:8088/6by42Build2/web-mobile/',
    ige_socket_url: 'ws://10.160.10.48:8183/JackpotEngine/socket',
  },
  ice: {
    gamesEnable: {
      dge: true,
      sle: true,
      bingo: true,
      ige: true,
      default: 'dge',
    },
    theme: ['cobalt-theme'],
    //domain: 'https://games.infinitilotto.com/api',
    domain: 'https://games.winlot.in/api',
    printDomain: 'http://192.168.132.68:3000',
    drawMachine_luckySix: 'https://dms.winlot.in/DrawMachines/Lucky6/web-mobile/',
    drawMachine_superKeno: 'https://dms.winlot.in/DrawMachines/SuperKeno/web-mobile/',
    drawMachine_lottoDiamond: 'https://dms.winlot.in/DrawMachines/LottoDiamond/web-mobile/',
    drawMachine_bichorapido: 'https://dms.winlot.in/DrawMachines/ZooLotto/web-mobile/',
    drawMachine_lottoamigo: 'https://dms.winlot.in/DrawMachines/LottoAmigo/web-mobile/',
    drawMachine_fiveByNinety: 'https://dms.winlot.in/DrawMachines/5by90/web-mobile/',
    drawMachine_twelvebytwentyfour: 'https://dms.winlot.in/DrawMachines/Lucky12/web-mobile/',
    dge_webSocket_domain: 'wss://dms.winlot.in/drawmachine',
    // drawMachine_luckySix: 'https://rms-ang.infinitilotto.com/LuckySix/web-mobile/',
    // drawMachine_superKeno: 'https://rms-ang.infinitilotto.com/SuperKeno/web-mobile/',
    // drawMachine_lottoDiamond: 'https://rms-ang.infinitilotto.com/AndmanLottoDiamond/web-mobile/',
    // drawMachine_fiveByNinety: 'https://rms-ang.infinitilotto.com/InfinitiLotto5by90/web-mobile/',
    // dge_webSocket_domain: 'wss://rms-ang.infinitilotto.com/drawmachine',
    dge_webSocket_user: 'user',
    dge_webSocket_pswd: 'user',
    chat_socket: 'https://bingo-games.infinitilotto.com:3234',
    ige_socket_url: 'wss://games-wls.infinitilotto.com/websocket',
  },
  guinee: {
    gamesEnable: {
      dge: true,
      sle: true,
      bingo: true,
      ige: true,
      default: 'dge',
    },
    theme: ['tropical-rain-forest-theme'],                   
    // domain: 'http://10.78.1.62:3001/api',
    domain: 'https://games-dev.winweaver.com/api',
    // printDomain: 'https://games-dev.winweaver.com',
    printDomain: 'http://192.168.132.68:3000',
    drawMachine_fiveByNinety: 'https://dms-dev.winweaver.com/DrawMachines/5By90/web-mobile/',
    drawMachine_eightbytwentyfour: 'https://dms-dev.winweaver.com/DrawMachines/8By24/web-mobile/',
    dge_webSocket_domain: 'wss://dms-dev.winweaver.com/drawmachine',
    dge_webSocket_user: 'user',
    dge_webSocket_pswd: 'user',
    chat_socket: 'https://bingo-dms-dev.winweaver.com:3234',
    ige_socket_url: 'wss://dms-dev.winweaver.com/websocket',
  },
};
                      