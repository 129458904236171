import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { ServerUrl } from '../_helpers';
import { DataService } from '../_helpers/data-service-handle';

@Injectable()
export class ReportsService {
  constructor(
        private dataservice: DataService,
        private alertService: AlertService) { }

  getServiceList() {
    return this.dataservice.postData(ServerUrl.REPORTS_GET_SERVICE_LIST, {}, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  getSaleReport({startDate : startDate, endDate : endDate, serviceCode :  serviceCode}) {
    return this.dataservice.postData(ServerUrl.REPORTS_GET_SALE_REPORT, {startDate : startDate, endDate : endDate, serviceCode :  serviceCode}, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }  

  getLedger({startDate : startDate, endDate : endDate}) {
    return this.dataservice.postData(ServerUrl.REPORTS_GET_LEDGER, {startDate : startDate, endDate : endDate}, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

  getSummarizedLedger({startDate : startDate, endDate : endDate, type: type}) {
    return this.dataservice.postData(ServerUrl.REPORTS_GET_SUMMARIZED_LEDGER, {startDate : startDate, endDate : endDate, type: type}, { withCredentials: true })
      .pipe(map((response : any) => {
        if (response.success) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
        }
        return response;
      }));
  }

}
