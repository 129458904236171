
import { environment } from 'src/environments/environment';
import { ServerUrl } from './server-url-handle';

export class Constants {

    static readonly TAB_WIDTH = 991;

    static CLIENT_CODE = `${environment.client}`;

    static CLIENT_ENV = `${environment.env}`;

    static CLIENT_THEME = environment[environment.client].theme;

    static CLIENT_GAMES = environment[environment.client].gamesEnable;

    static readonly MY_TICKETS_STATUS_MAP = {
        'WIN': 'loader',
        'CLAIMED': 'loader',
        'UNCLAIMED': 'loader',
        'SOLD': 'loader',
        'CANCELLED': 'loader',
        'NON-WIN': ''
    };

    static readonly DRAW_ENGINE = 'DMS';
    static readonly SLE_ENGINE = 'SLE';
    static readonly BIG_BOSS_ENGINE = 'BIG_BOSS';
    static readonly BINGO_ENGINE = 'BINGO';
    static readonly IGE_ENGINE = 'IGE';
    static readonly SGE_ENGINE = 'SGE';
    static readonly SGE2_ENGINE = 'SGE2';
    static readonly CAP_ENGINE = 'CAP';

    static readonly LUCKY_SIX = 'LuckySix';
    static readonly SUPER_KENO = 'SuperKeno';
    static readonly THAI_LOTTERY = 'ThaiLottery';
    static readonly LOTTERY_DIAMOND = 'ThaiLotteryHighFrequency';
    static readonly TWELVE_BY_TWENTY_FOUR = 'TwelveByTwentyFour';
    static readonly EIGHT_BY_TWENT_FOUR = 'EightByTwentyFour';
    static readonly ONE_BY_TWELVE = 'OneByTwelve';
    static readonly POWERBALL = 'powerball';
    static readonly BONUS_LOTTO = 'bonusLotto';
    static readonly SIXBYFORTYTWO_BONUSBALL = 'SixByFortyTwoBonusBall';
    static readonly FIVEBYFORTYNINE_LOTTOWEEKLY = 'FiveByFortyNineLottoWeekly';
    static readonly TEN_BY_TWENTY = 'TenByTwenty';
    static readonly FIVE_BY_NINETY = 'FiveByNinety';
    static readonly MINI_ROULETTE = 'MiniRoulette';
    static readonly FULL_ROULETTE = 'FullRoulette';
    static readonly MYANMAR_2D = 'TwoDMYANMAAR';
    static readonly DAILY_LOTTO = 'DailyLotto';
    static readonly BichoRapido = 'BichoRapido';
    static readonly BingoSeventyFive3 = 'BingoSeventyFive3';
    static readonly Zero_TO_Nine = 'Zerotonine';

    static readonly SOCCER_13 = 'soccer13';
    static readonly SOCCER_6 = 'soccer6';
    static readonly SOCCER_4 = 'soccer4';
    static readonly SOCCER_15 = 'soccer15';

    static readonly BIG_BOSS_WINNER = 'BIG_BOSS_WINNER';
    static readonly BIG_BOSS_ELIMINATOR = 'BIG_BOSS_ELIMINATOR';

    static readonly BINGO_SEVENTY_FIVE = 'BingoSeventyFive';
    static readonly BINGO_NINETY = 'BingoNinety';
    static readonly BINGO_SEVENTY_FIVE_1 = 'BingoSeventyFive1';
    static readonly BINGO_NINETY_1 = 'BingoNinety1';
    static readonly BINGO_SEVENTY_FIVE_2 = 'BingoSeventyFive2';
    static readonly BINGO_NINETY_2 = 'BingoNinety2';
    // static readonly BINGO_SEVENTY_FIVE_3 = 'BingoSeventyFive3';

    static readonly WEBSOCKET_USER = `${environment[environment.client].dge_webSocket_user}`;
    static readonly WEBSOCKET_PSWD = `${environment[environment.client].dge_webSocket_pswd}`;

    static readonly EVENING_START_HOURS = 16;

    static readonly IGE_DEMO_STATUS = {
      'myanmar': false,
      'cameroon':false,
      'kenya': false,
      'ice': true,
      'guinee': true,
    };

    static readonly IGE_JACKPOT_ENABLE = {
        'myanmar': {
            status: true,
            odometerConfig: {
                animation: 'slide', // Options: 'slide', 'count'
                format: '(,ddd).dd', // (,ddd) - 12,345,678  (,ddd).dd - 12,345,678.09  (.ddd),dd - 12.345.678,09  ( ddd),dd - 12 345 678,09
                theme: 'plaza', // Options: 'default', 'minima', 'digital', 'car', 'plaza', 'slot-machine', 'train-station'
                value: 0,
                auto: true
            },
            countUpConfig: {
                startVal: 0.00,
                decimalPlaces: 2,
                duration: 2,
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: '',
                numerals: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
            },
        },
        'cameroon': {
            status: true,
            odometerConfig: {
                animation: 'slide', // Options: 'slide', 'count'
                format: '( ddd),dd', // (,ddd) - 12,345,678  (,ddd).dd - 12,345,678.09  (.ddd),dd - 12.345.678,09  ( ddd),dd - 12 345 678,09
                theme: 'plaza', // Options: 'default', 'minima', 'digital', 'car', 'plaza', 'slot-machine', 'train-station'
                value: 0,
                auto: true
            },
            countUpConfig: {
                startVal: 0.00,
                decimalPlaces: 2,
                duration: 2,
                useEasing: true,
                useGrouping: true,
                separator: ' ',
                decimal: ',',
                prefix: '',
                suffix: '',
                numerals: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
            },
        },
        'kenya': {
            status: true,
            odometerConfig: {
                animation: 'slide', // Options: 'slide', 'count'
                format: '(,ddd).dd', // (,ddd) - 12,345,678  (,ddd).dd - 12,345,678.09  (.ddd),dd - 12.345.678,09  ( ddd),dd - 12 345 678,09
                theme: 'plaza', // Options: 'default', 'minima', 'digital', 'car', 'plaza', 'slot-machine', 'train-station'
                value: 0,
                auto: true
            },
            countUpConfig: {
                startVal: 0.00,
                decimalPlaces: 2,
                duration: 2,
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: '',
                numerals: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
            },
        },
        'ice': {
            status: true,
            odometerConfig: {
                animation: 'slide', // Options: 'slide', 'count'
                format: '(,ddd).dd', // (,ddd) - 12,345,678  (,ddd).dd - 12,345,678.09  (.ddd),dd - 12.345.678,09  ( ddd),dd - 12 345 678,09
                theme: 'plaza', // Options: 'default', 'minima', 'digital', 'car', 'plaza', 'slot-machine', 'train-station'
                value: 0,
                auto: true
            },
            countUpConfig: {
                startVal: 0.00,
                decimalPlaces: 2,
                duration: 2,
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: '',
                numerals: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
            },
        },
        'arabia': {
            status: true,
            odometerConfig: {
                animation: 'slide', // Options: 'slide', 'count'
                format: '(,ddd).dd', // (,ddd) - 12,345,678  (,ddd).dd - 12,345,678.09  (.ddd),dd - 12.345.678,09  ( ddd),dd - 12 345 678,09
                theme: 'plaza', // Options: 'default', 'minima', 'digital', 'car', 'plaza', 'slot-machine', 'train-station'
                value: 0,
                auto: true
            },
            countUpConfig: {
                startVal: 0.00,
                decimalPlaces: 2,
                duration: 2,
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: '',
                numerals: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
            },
        },
        'guinee': {
            status: true,
            odometerConfig: {
                animation: 'slide', // Options: 'slide', 'count'
                format: '(,ddd).dd', // (,ddd) - 12,345,678  (,ddd).dd - 12,345,678.09  (.ddd),dd - 12.345.678,09  ( ddd),dd - 12 345 678,09
                theme: 'plaza', // Options: 'default', 'minima', 'digital', 'car', 'plaza', 'slot-machine', 'train-station'
                value: 0,
                auto: true
            },
            countUpConfig: {
                startVal: 0.00,
                decimalPlaces: 2,
                duration: 2,
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: '',
                numerals: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
            },
        },
    }

    static readonly CLIENT_GAMES_MAPPING = {
        'myanmar': {
            'DMS': ['LuckySix', 'SuperKeno', 'ThaiLottery', 'ThaiLotteryHighFrequency', 'TwoDMYANMAAR'],
            // 'DMS': ['LuckySix', 'SuperKeno', 'FiveByNinety', 'TwelveByTwentyFour', 'OneByTwelve', 'powerball', 'bonusLotto', 'ThaiLottery', 'ThaiLotteryHighFrequency'],
            'SLE': ['soccer4', 'soccer6', 'soccer13'],
            'BIG_BOSS': ['BIG_BOSS_WINNER', 'BIG_BOSS_ELIMINATOR'],
            'BINGO': ['BingoSeventyFive', 'BingoSeventyFive1', 'BingoSeventyFive2', 'BingoNinety', 'BingoNinety1', 'BingoNinety2']
        },
        'cameroon': {
            'DMS': ['LuckySix', 'SuperKeno', 'FiveByNinety'],
            'SLE': ['soccer4', 'soccer6', 'soccer13'],
            'BIG_BOSS': ['BIG_BOSS_WINNER', 'BIG_BOSS_ELIMINATOR'],
        },
        'kenya': {
            'DMS': ['TwelveByTwentyFour', 'powerball'],
            'SLE': ['soccer4', 'soccer6', 'soccer13'],
        },
        'ice': {
            //'DMS': ['LuckySix', 'SuperKeno', 'FiveByNinety', 'TwelveByTwentyFour', 'OneByTwelve', 'powerball', 'bonusLotto', 'ThaiLotteryHighFrequency'],
            'DMSB': [ 'FiveByNinety' ],
            'DMSG': [ 'EightByTwentyFour', 'FiveByNinety'],
            'DMS': [ 'FiveByNinety', 'LuckySix', 'TwelveByTwentyFour', 'SuperKeno', 'DailyLotto', 'SixByFortyTwoBonusBall', 'FiveByFortyNineLottoWeekly', 'Zerotonine', 'OneByTwelve', 'TwoDMYANMAAR', 'BichoRapido',   'BingoSeventyFive3', 'MiniRoulette',  'ThaiLottery' ],         //  powerball
            'SLE': [ 'soccer4', 'soccer6', 'soccer13', 'soccer15' ],
            'BINGO': [ 'BingoSeventyFive', 'BingoSeventyFive1', 'BingoSeventyFive2', 'BingoNinety', 'BingoNinety1', 'BingoNinety2' ]
        },
        'arabia': {
            'DMS': ['LuckySix', 'SuperKeno', 'ThaiLottery', 'ThaiLotteryHighFrequency', 'TwoDMYANMAAR'],
            // 'DMS': ['LuckySix', 'SuperKeno', 'FiveByNinety', 'TwelveByTwentyFour', 'OneByTwelve', 'powerball', 'bonusLotto', 'ThaiLottery', 'ThaiLotteryHighFrequency'],
            'SLE': ['soccer4', 'soccer6', 'soccer13'],
            'BIG_BOSS': ['BIG_BOSS_WINNER', 'BIG_BOSS_ELIMINATOR'],
            'BINGO': ['BingoSeventyFive', 'BingoSeventyFive1', 'BingoSeventyFive2', 'BingoNinety', 'BingoNinety1', 'BingoNinety2']
        },
        'guinee': {
            'DMS': [ 'EightByTwentyFour', 'FiveByNinety'],
            'SLE': [ '' ],
            'BINGO': [ '' ]
        },
    };

    static readonly CLIENT_PLAYERWISE_GAMES_MAPPING = {
        '3971': {playerId: '3971', clientWiseGameEngine: 'DMSB', mobileNumber: '3563463463', clientName: 'Balgeria'},
        '4030': {playerId: '4030', clientWiseGameEngine: 'DMSG', mobileNumber: '7812345678', clientName: 'Guniee'},
    };

    static readonly CLIENT_IGE_VENDOR_PAGING = {
        'myanmar': false,
        'cameroon': false,
        'kenya': false,
        'ice': false,
        'arabia': false,
        'guinee': false,
    };

    static readonly CLIENT_MULTI_CURRENCY = {
        'myanmar': true,
        'cameroon': false,
        'kenya': false,
        'ice': false,
        'arabia': true,
        'guinee': false,
    };

    static readonly CLIENT_SGE_VENDOR_PAGING = {
        'myanmar': false,
        'cameroon': false,
        'kenya': false,
        'ice': false,
        'arabia': false,
        'guinee': false,
    };

    static readonly CLIENT_SGE2_VENDOR_PAGING = {
        'myanmar': false,
        'cameroon': false,
        'kenya': false,
        'ice': false,
        'arabia': false,
        'guinee': false,
    };

    static readonly CLIENT_CAP_VENDOR_PAGING = {
        'myanmar': false,
        'cameroon': false,
        'kenya': false,
        'ice': false,
        'arabia': false,
        'guinee': false,
    };

    static readonly CLIENT_CURRENCY_LOCALE_MAPPING = {
        'myanmar': 'th-TH',
        'cameroon': 'fr-CM',
        'kenya': 'en-US',
        'ice': 'en-US',
        'arabia': 'ar-AE',
        'guinee': 'en-US',
    };

    static readonly CLIENT_RESULTS_HARDCODE = {
        'myanmar': {
            'DMS': {
                'ThaiLottery': [{
                    "lastDrawDateTime": "2020-06-16 09:00:00.0",
                    "winningNumber": "5#1#6,9#6#7,8#7#6,8#8#2,5#6#5,6#2#5,-1#6#4",
                }]
            },
        },
        'cameroon': {
            'DMS': {},
        },
        'kenya': {
            'DMS': {},
        },
        'ice': {
            'DMS': {},
        },
        'arabia': {
            'DMS': {},
        },
        'guinee': {
            'DMS': {},
        },
    };

    static readonly CLIENT_CONVERSION_PIXEL = {
        'myanmar': {
            server: 'https://camwinlotto.go2oh.net/pb_x',
            offer_id: 1,
            adv_key: 'ZmOUNRQ740Dmu1hx',
            url: 'https://camwinlotto.go2oh.net/pb_x?offer_id=1&adv_key=ZmOUNRQ740Dmu1hx'
        },
        'cameroon': {
            server: 'https://thursdayadvertising.go2oh.net/pb_x',
            offer_id: 5,
            adv_key: 'ZmOUNRQ740Dmu1hx',
            url: 'https://thursdayadvertising.go2oh.net/pb_x?offer_id=5&adv_key=ZmOUNRQ740Dmu1hx'
        },
        'kenya': {},
        'ice': {},
        'arabia': {},
        'guinee': {},
    };

    static readonly OneByTwelve_ICON_MAPPING = {
        "01": "aries", "02": "taurus", "03": "gemini", "04": "cancer",
        "05": "leo", "06": "virgo", "07": "libra", "08": "scorpio",
        "09": "sagittarius", "10": "capricorn", "11": "aquarius", "12": "pisces",
    };

    static readonly NUMBER_MAPPING = {
      "0": "0 - Zero", "1": "1 - One", "2": "2 - Two", "3": "3 - Three", "4": "4 - Four", "5": "5 - Five", "6": "6 - Six", "7": "7 - Seven", "8": "8 - Eight", "9": "9 - Nine",
    };

    static readonly GAME_DATA = {
        'DMS': {
            'Zerotonine': {
                'gameIcon': 'logo-fastlotto.png',
                'resultClass': 'result-zerotonine',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_ZOOLOTTO,
                'dispName': 'Fast Lotto',
                'panelType': 3,
            },
            'LuckySix': {
                'gameIcon': 'logo-luckySix.png',
                'resultClass': 'lucky6',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_LUCKYSIX,
                'dispName': 'Lucky 6',
                'panelType': 1,
            },
            'SuperKeno': {
                'gameIcon': 'logo-superKeno.png',
                'resultClass': 'superkeno',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_SUPERKENO,
                'dispName': 'Super Keno',
                'panelType': 1,
            },
            'ThaiLottery': {
                'gameIcon': 'logo-thaiLottery.png',
                'resultClass': 'thailottery',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_THAILOTTERY,
                'dispName': 'Thai Lottery',
                'panelType': 2,
            },
            'ThaiLotteryHighFrequency': {
                'gameIcon': 'logo-lottoDiamond.png',
                'resultClass': 'thailottery',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_LOTTODIAMOND,
                'dispName': 'Lotto Diamond',
                'panelType': 2,
            },
            'TwoDMYANMAAR': {
                'gameIcon': 'logo-myanmar2d.png',
                'resultClass': 'myanmar2d',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_2D_MYANMAR,
                'dispName': '2D-Myanmar',
                'panelType': 2,
            },
            'FiveByNinety': {
                'gameIcon': 'logo-luckyNumber.png',
                'resultClass': 'fivebyninety',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_FIVEBYNINETY,
                'dispName': 'Lucky Number 5/90',
                'panelType': 1,
            },
            'TwelveByTwentyFour': {
                'gameIcon': 'logo-12by24.png',
                'resultClass': 'result-12by24',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_TWELVEBYTWENTYFOUR,
                'dispName': 'Power Play 12/24',
                'panelType': 1,
            },
            'EightByTwentyFour': {
                'gameIcon': 'logo-force-lotto.png',
                'resultClass': 'result-12by24',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_EIGHTBYTWENTYFOUR,
                'dispName': 'Force Lotto 8/24',
                'panelType': 1,
            },
            'powerball': {
                'gameIcon': 'logo-force-lotto.png',
                'gameIcon1': 'logo-force-lotto_plus.png',
                'resultClass': 'result-6by42',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_POWERBALL,
                'dispName': 'SABANZURI Lotto',
                'panelType': 1,
            },
            'OneByTwelve': {
                'gameIcon': 'logo-1by12.png',
                'resultClass': 'result-1by12',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_FORTUNE,
                'dispName': 'Fortune',
                'panelType': 3,
            },
            'bonusLotto': {
                'gameIcon': 'logo-bonusLotto.png',
                'resultClass': 'result-6by42',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_BONUSLOTTO,
                'dispName': 'Bonus Lotto',
                'panelType': 1,
            },
            'TenByTwenty': {
                'gameIcon': 'TenByTwenty.png',
                'resultClass': 'game-10by20',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': true,
                'timer': true,
                'draw_machine_url': '',
                'dispName': '',
                'panelType': 1,
            },
            'SixByFortyTwoBonusBall': {
                'gameIcon': 'logo-bonusLotto-6by42.png',
                'resultClass': 'result-6by42',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_BONUSLOTTO,
                'dispName': 'Bonus Ball',
                'panelType': 1,
            },
            'FiveByFortyNineLottoWeekly': {
                'gameIcon': 'logo-5by49lottoWeekly.png',
                'resultClass': 'result-5by49',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': ServerUrl.DRAWMACHINE_BONUSLOTTO,
                'dispName': 'Lotto Weekly',
                'panelType': 1,
            },
            'FullRoulette': {
                'gameIcon': 'logo-rouletteMax.png',
                'resultClass': 'game-fullroulette',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': '',
                'dispName': '',
                'panelType': 3,
            },
            'MiniRoulette': {
                'gameIcon': 'logo-roultetteMini.png',
                'resultClass': 'game-miniroulette',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'draw_machine_url': '',
                'dispName': '',
                'panelType': 1,
            },
            'DailyLotto': {
              'gameIcon': 'logo-5by36.png',
              'resultClass': 'superkeno',
              'resultTabCounter': 3,
              'maxPanelLimit': 1,
              'jackpot': false,
              'timer': true,
              'draw_machine_url': ServerUrl.DRAWMACHINE_SUPERKENO,
              'dispName': 'DailyLotto',
              'panelType': 1,
          },
          'BichoRapido': {
            'gameIcon': 'logo-zooLotto.png',
            'resultClass': 'bichorapido',
            'resultTabCounter': 3,
            'maxPanelLimit': 1,
            'jackpot': false,
            'timer': true,
            'draw_machine_url': ServerUrl.DRAWMACHINE_ZOOLOTTO,
            'dispName': 'Zoo Lotto',
            'panelType': 2,
        },
        'BingoSeventyFive3': {
          'gameIcon': 'logo-lottoAmigo.png',
          'resultClass': 'bingoseventyfive3',
          'resultTabCounter': 3,
          'maxPanelLimit': 1,
          'jackpot': false,
          'timer': true,
          'draw_machine_url': ServerUrl.DRAWMACHINE_LOTTOAMIGO,
          'dispName': 'Lotto Amigo',
          'panelType': 2,
          'totalCards': 15,
          'totalBlocks': [5, 5],
          'lobbyClass': 'rm-super75', /* rm-elegant rm-classic rm-fortune90*/
          'cardClass': 'bingo75',
          'rankMap': [
              {
                  "match": "Seven Pattern",
                  "rank": 1,
                  "pattern": null,
                  "regex": [
                      "(X,){4}X#((\\d{2}|X),){4}X#((\\d{2}|X),){2}(X,){2}(\\d{2}|X)#((\\d{2}|X),X,((\\d{2}|X),){2}(\\d{2}|X)(#|$)){2}"
                  ],
                  "regexRow": []
              }
          ],
        },
        },
        'SLE': {
            'soccer13': {
                'gameIcon': 'logo-soccer13.png',
                'resultClass': 'game-soccer13',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'dispName': 'Soccer 13',
                'gameTypeId': 1,
            },
            'soccer15': {
                'gameIcon': 'logo-soccer15.png',
                'resultClass': 'game-soccer15',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'dispName': 'Soccer 15',
                'gameTypeId': 1,
            },
            'soccer6': {
                'gameIcon': 'logo-soccer6.png',
                'resultClass': 'game-soccer6',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'dispName': 'Soccer 6',
                'gameTypeId': 2,
            },
            'soccer4': {
                'gameIcon': 'logo-soccer4.png',
                'resultClass': 'game-soccer4',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'dispName': 'Soccer 4',
                'gameTypeId': 3,
            },
        },
        'BIG_BOSS': {
            'BIG_BOSS_WINNER': {
                'gameIcon': 'logo-bigbosswinner.png',
                'resultClass': 'game-soccer4',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'dispName': 'Who will win Bigboss session 2020',
                'gameTypeId': 6,
            },
            'BIG_BOSS_ELIMINATOR': {
                'gameIcon': 'logo-bigbosseliminator.png',
                'resultClass': 'game-soccer4',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': false,
                'timer': true,
                'dispName': 'Who will loose Bigboss session 2020',
                'gameTypeId': 7,
            },
        },
        'BINGO': {
            'BingoSeventyFive': {
                'gameIcon': 'classic.png',
                'resultClass': 'bingo75',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': true,
                'timer': true,
                'dispName': 'BingoSeventyFive',
                'totalCards': 15,
                'totalBlocks': [5, 5],
                'lobbyClass': 'rm-classic', /* rm-elegant rm-classic rm-fortune90*/
                'cardClass': 'bingo75',
                'rankMap': [
                    {
                        "match": "full house",
                        "rank": 1,
                        "pattern": "0-0,1-0,2-0,3-0,4-0#0-1,1-1,2-1,3-1,4-1#0-2,1-2,2-2,3-2,4-2#0-3,1-3,2-3,3-3,4-3#0-4,1-4,2-4,3-4,4-4",
                        "regex": [
                            "((X,){4}X(#|$)){5}"
                        ],
                        "regexRow": ["0-1-2-3-4"]
                    },
                    {
                        "match": "Picture Frame",
                        "rank": 2,
                        "pattern": "0-0,0-1,0-2,0-3,0-4#1-0,1-4#2-0,2-4#3-0,3-4#4-0,4-1,4-2,4-3,4-4",
                        "regex": [
                            "((X,){4}X#)(X,((\\d{2}|X),){3}X#){3}((X,){4}X$)"
                        ],
                        "regexRow": []
                    },
                    {
                        "match": "Cross",
                        "rank": 3,
                        "pattern": "0-0,0-4#1-1,1-3#2-2#3-1,3-3#4-0,4-4",
                        "regex": [
                            "(X,((\\d{2}|X),){3}X#)(((\\d{2}|X),X,){2}(\\d{2}|X)#)(((\\d{2}|X),){2}X,((\\d{2}|X),)(\\d{2}|X)#)((\\d{2}|X),X,){2}((\\d{2}|X)#)(X,((\\d{2}|X),){3}X$)"
                        ],
                        "regexRow": []
                    },
                    {
                        "match": "Any Two Rows",
                        "rank": 4,
                        "pattern": "0-0,0-1,0-2,0-3,0-4#1-0,1-1,1-2,1-3,1-4",
                        "regex": [
                            "((X,){4}X#){2}(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){3}",
                            "((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)(#)){1}((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){2}",
                            "((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)(#)){2}((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)){1}$",
                            "((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)(#)){3}((X,){4}X){1}$",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){1}((X,){4}X#){2}(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){2}",
                            "((((\\d{2}|X),){4}(\\d{2}|X)(#)){1}((X,){4}X#){1}){2}(((\\d{2}|X),){4}(\\d{2}|X)($)){1}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){1}((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)(#)){2}((X,){4}X){1}$",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){2}((X,){4}X#){2}(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){1}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){2}((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)(#)){1}((X,){4}X){1}$",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){3}((X,){4}X(#|$)){2}"
                        ],
                        "regexRow": ["0-1", "0-2", "0-3", "0-4", "1-2", "1-3", "1-4", "2-3", "2-4", "3-4"]
                    },
                    {
                        "match": "Any One Row",
                        "rank": 5,
                        "pattern": "0-0,0-1,0-2,0-3,0-4",
                        "regex": [
                            "(X,){4}X#(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){4}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){1}(X,){4}X#(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){3}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){2}(X,){4}X#(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){2}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){3}(X,){4}X#(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){1}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)#){4}(X,){4}X$"
                        ],
                        "regexRow": ["0", "1", "2", "3", "4"]
                    },
                    {
                        "match": "corner",
                        "rank": 6,
                        "pattern": "0-0,4-0#0-4,4-4",
                        "regex": [
                            "((X,)(\\d{2}|X),(\\d{2}|X),(\\d{2}|X),{1}X#)(((\\d{2}|X),){4}(\\d{2}|X)#){3}((X,)((\\d{2}|X),){3}(X|$))"
                        ],
                        "regexRow": []
                    }
                ],
            },
            'BingoSeventyFive1': {
                'gameIcon': 'super75.png',
                'resultClass': 'bingo75',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': true,
                'timer': true,
                'dispName': 'BingoSeventyFive1',
                'totalCards': 15,
                'totalBlocks': [5, 5],
                'lobbyClass': 'rm-super75', /* rm-elegant rm-classic rm-fortune90*/
                'cardClass': 'bingo75',
                'rankMap': [
                    {
                        "match": "Seven Pattern",
                        "rank": 1,
                        "pattern": "0-0,0-1,0-2,0-3,0-4#1-4#2-2,2-3#3-1#4-1",
                        "regex": [
                            "(X,){4}X#((\\d{2}|X),){4}X#((\\d{2}|X),){2}(X,){2}(\\d{2}|X)#((\\d{2}|X),X,((\\d{2}|X),){2}(\\d{2}|X)(#|$)){2}"
                        ],
                        "regexRow": []
                    }
                ],
            },
            'BingoSeventyFive2': {
                'gameIcon': 'powerbingo75.png',
                'resultClass': 'bingo75',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': true,
                'timer': true,
                'dispName': 'PowerBingo75',
                'totalCards': 15,
                'totalBlocks': [5, 5],
                'lobbyClass': 'rm-powerbingo75', /* rm-elegant rm-classic rm-fortune90*/
                'cardClass': 'bingo75',
                'rankMap': [
                    {
                        "match": "Full House",
                        "rank": 1,
                        "pattern": "0-0,0-1,0-2,0-3,0-4#1-0,1-1,1-2,1-3,1-4#2-0,2-1,2-2,2-3,2-4#3-0,3-1,3-2,3-3,3-4#4-0,4-1,4-2,4-3,4-4",
                        "regex": [
                            "((X,){4}X(#|$)){5}"
                        ],
                        "regexRow": ["0-1-2-3-4"]
                    },
                    {
                        "match": "Four Rows",
                        "rank": 2,
                        "pattern": "0-0,0-1,0-2,0-3,0-4#1-0,1-1,1-2,1-3,1-4#2-0,2-1,2-2,2-3,2-4#3-0,3-1,3-2,3-3,3-4",
                        "regex": [
                            "((X,){4}X#){4}((\\d{2}|X),){4}(\\d{2}|X)$",
                            "((X,){4}X#){3}(((\\d{2}|X),){4}(\\d{2}|X)#)(X,){4}X$",
                            "((X,){4}X#){2}(((\\d{2}|X),){4}(\\d{2}|X)#)((X,){4}X#)(X,){4}X$",
                            "((X,){4}X#)(((\\d{2}|X),){4}(\\d{2}|X)#)((X,){4}X#){2}(X,){4}X$",
                            "(((\\d{2}|X),){4}(\\d{2}|X)#)((X,){4}X#){3}(X,){4}X$"
                        ],
                        "regexRow": ["0-1-2-3", "0-1-2-4", "0-1-3-4", "0-2-3-4", "1-2-3-4"]
                    },
                    {
                        "match": "Three Rows",
                        "rank": 3,
                        "pattern": "0-0,0-1,0-2,0-3,0-4#1-0,1-1,1-2,1-3,1-4#2-0,2-1,2-2,2-3,2-4",
                        "regex": [
                            "((X,){4}X#){3}(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){2}",
                            "((X,){4}X#){2}((\\d{2}|X),){4}(\\d{2}|X)#((X,){4}X#)((\\d{2}|X),){4}(\\d{2}|X)$",
                            "((X,){4}X#){2}(((\\d{2}|X),){4}(\\d{2}|X)#){2}((X,){4}X)$",
                            "((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)#){1}((X,){4}X#){2}(((\\d{2}|X),){4}(\\d{2}|X)){1}$",
                            "(((X,){4}X#)(((\\d{2}|X),){4}(\\d{2}|X)#)){2}((X,){4}X)$",
                            "((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)#){2}((X,){4}X(#|$)){2}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)#){1}((X,){4}X#){3}(((\\d{2}|X),){4}(\\d{2}|X)$)",
                            "(((\\d{2}|X),){4}(\\d{2}|X)#)((X,){4}X#){2}(((\\d{2}|X),){4}(\\d{2}|X)#)((X,){4}X)$",
                            "((((\\d{2}|X),){4}(\\d{2}|X)#)((X,){4}X#)){2}((X,){4}X)$",
                            "(((\\d{2}|X),){4}(\\d{2}|X)#){1}((X,){4}X#){3}(((\\d{2}|X),){4}(\\d{2}|X)$)"
                        ],
                        "regexRow": ["0-1-2", "0-1-3", "0-1-4", "0-2-3", "0-2-4", "0-3-4", "1-2-3", "1-2-4", "1-3-4", "2-3-4"]
                    },
                    {
                        "match": "Two Rows",
                        "rank": 4,
                        "pattern": "0-0,0-1,0-2,0-3,0-4#1-0,1-1,1-2,1-3,1-4",
                        "regex": [
                            "((X,){4}X#){2}(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){3}",
                            "((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)(#)){1}((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){2}",
                            "((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)(#)){2}((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)){1}$",
                            "((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)(#)){3}((X,){4}X){1}$",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){1}((X,){4}X#){2}(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){2}",
                            "((((\\d{2}|X),){4}(\\d{2}|X)(#)){1}((X,){4}X#){1}){2}(((\\d{2}|X),){4}(\\d{2}|X)($)){1}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){1}((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)(#)){2}((X,){4}X){1}$",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){2}((X,){4}X#){2}(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){1}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){2}((X,){4}X#){1}(((\\d{2}|X),){4}(\\d{2}|X)(#)){1}((X,){4}X){1}$",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){3}((X,){4}X(#|$)){2}"
                        ],
                        "regexRow": ["0-1", "0-2", "0-3", "0-4", "1-2", "1-3", "1-4", "2-3", "2-4", "3-4"]
                    },
                    {
                        "match": "One Row",
                        "rank": 5,
                        "pattern": "0-0,0-1,0-2,0-3,0-4",
                        "regex": [
                            "(X,){4}X#(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){4}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){1}(X,){4}X#(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){3}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){2}(X,){4}X#(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){2}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)(#)){3}(X,){4}X#(((\\d{2}|X),){4}(\\d{2}|X)(#|$)){1}",
                            "(((\\d{2}|X),){4}(\\d{2}|X)#){4}(X,){4}X$"
                        ],
                        "regexRow": ["0", "1", "2", "3", "4"]
                    },
                    {
                        "match": "Corner",
                        "rank": 6,
                        "pattern": "0-0,0-4#4-0,4-4",
                        "regex": [
                            "((X,)(\\d{2}|X),(\\d{2}|X),(\\d{2}|X),{1}X#)(((\\d{2}|X),){4}(\\d{2}|X)#){3}((X,)((\\d{2}|X),){3}(X|$))"
                        ],
                        "regexRow": []
                    }
                ],
            },
            'BingoSeventyFive3': {
              'gameIcon': 'super75.png',
              'resultClass': 'bingo75',
              'resultTabCounter': 3,
              'maxPanelLimit': 1,
              'jackpot': true,
              'timer': true,
              'dispName': 'BingoSeventyFive3',
              'totalCards': 15,
              'totalBlocks': [5, 5],
              'lobbyClass': 'rm-super75', /* rm-elegant rm-classic rm-fortune90*/
              'cardClass': 'bingo75',
              'rankMap': [
                  {
                      "match": "Seven Pattern",
                      "rank": 1,
                      "pattern": null,
                      "regex": [
                          "(X,){4}X#((\\d{2}|X),){4}X#((\\d{2}|X),){2}(X,){2}(\\d{2}|X)#((\\d{2}|X),X,((\\d{2}|X),){2}(\\d{2}|X)(#|$)){2}"
                      ],
                      "regexRow": []
                  }
              ],
          },
            'BingoNinety': {
                'gameIcon': 'elegant.png',
                'resultClass': 'bingo90',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': true,
                'timer': true,
                'dispName': 'BingoNinety',
                'totalCards': 15,
                'totalBlocks': [3, 9],
                'lobbyClass': 'rm-elegant',
                'cardClass': 'bingo90',
                'rankMap': [
                    {
                        "match": "Last Row",
                        "rank": 1,
                        "pattern": "2-0,2-1,2-2,2-3,2-4,2-5,2-6,2-7,2-8",
                        "regex": [
                            "(((\\d{2}|X),){8}(\\d{2}|X)#){2}((X,){8}X$)"
                        ],
                        "regexRow": ["2"]
                    },
                    {
                        "match": "Middle Row",
                        "rank": 2,
                        "pattern": "1-0,1-1,1-2,1-3,1-4,1-5,1-6,1-7,1-8",
                        "regex": [
                            "(((\\d{2}|X),){8}(\\d{2}|X)#)((X,){8}X#)(((\\d{2}|X),){8}(\\d{2}|X)$)"
                        ],
                        "regexRow": ["1"]
                    },
                    {
                        "match": "First Row",
                        "rank": 3,
                        "pattern": "0-0,0-1,0-2,0-3,0-4,0-5,0-6,0-7,0-8",
                        "regex": [
                            "((X,){8}X#)(((\\d{2}|X),){8}(\\d{2}|X)(#|$)){2}"
                        ],
                        "regexRow": ["0"]
                    }
                ],
            },
            'BingoNinety1': {
                'gameIcon': 'fortune90.png',
                'resultClass': 'bingo90',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': true,
                'timer': true,
                'dispName': 'BingoNinety1',
                'totalCards': 15,
                'totalBlocks': [3, 9],
                'lobbyClass': 'rm-fortune90',
                'cardClass': 'bingo90',
                'rankMap': [
                    {
                        "match": "First box",
                        "rank": 1,
                        "pattern": "0-0,0-1,0-2#1-0,1-1,1-2#2-0,2-1,2-2",
                        "regex": [
                            "((X,){3}((\\d{2}|X),){5}((\\d{2}|X)(#|$))){3}"
                        ],
                        "regexRow": []
                    },
                    {
                        "match": "Second box",
                        "rank": 2,
                        "pattern": "0-3,0-4,0-5#1-3,1-4,1-5#2-3,2-4,2-5",
                        "regex": [
                            "(((\\d{2}|X),){3}(X,){3}((\\d{2}|X),){2}((\\d{2}|X)(#|$))){3}"
                        ],
                        "regexRow": []
                    },
                    {
                        "match": "Third box",
                        "rank": 3,
                        "pattern": "0-6,0-7,0-8#1-6,1-7,1-8#2-6,2-7,2-8",
                        "regex": [
                            "(((\\d{2}|X),){6}(X,){2}X(#|$)){3}"
                        ],
                        "regexRow": []
                    }
                ],
            },
            'BingoNinety2': {
                'gameIcon': 'powerbingo90.png',
                'resultClass': 'bingo90',
                'resultTabCounter': 3,
                'maxPanelLimit': 1,
                'jackpot': true,
                'timer': true,
                'dispName': 'PowerBingo90',
                'totalCards': 15,
                'totalBlocks': [3, 9],
                'lobbyClass': 'rm-powerbingo90',
                'cardClass': 'bingo90',
                'rankMap': [
                    {
                        "match": "Full House",
                        "rank": 1,
                        "pattern": "0-0,0-1,0-2,0-3,0-4,0-5,0-6,0-7,0-8#1-0,1-1,1-2,1-3,1-4,1-5,1-6,1-7,1-8#2-0,2-1,2-2,2-3,2-4,2-5,2-6,2-7,2-8",
                        "regex": [
                            "((X,){8}X(#|$)){3}"
                        ],
                        "regexRow": ["0-1-2"]
                    },
                    {
                        "match": "Any Two Rows",
                        "rank": 2,
                        "pattern": "0-0,0-1,0-2,0-3,0-4,0-5,0-6,0-7,0-8#1-0,1-1,1-2,1-3,1-4,1-5,1-6,1-7,1-8",
                        "regex": [
                            "((X,){8}X#){2}(((\\d{2}|X),){8}(\\d{2}|X)$)",
                            "((X,){8}X#)(((\\d{2}|X),){8}(\\d{2}|X)#)((X,){8}X$)",
                            "(((\\d{2}|X),){8}(\\d{2}|X)#)((X,){8}X(#|$)){2}"
                        ],
                        "regexRow": ["0-1", "0-2", "1-2"]
                    },
                    {
                        "match": "Any One Row",
                        "rank": 3,
                        "pattern": "0-0,0-1,0-2,0-3,0-4,0-5,0-6,0-7,0-8",
                        "regex": [
                            "((X,){8}X#)(((\\d{2}|X),){8}(\\d{2}|X)(#|$)){2}",
                            "(((\\d{2}|X),){8}(\\d{2}|X)#)((X,){8}X#)(((\\d{2}|X),){8}(\\d{2}|X)$)",
                            "(((\\d{2}|X),){8}(\\d{2}|X)#){2}((X,){8}X$)"
                        ],
                        "regexRow": ["0", "1", "2"]
                    }
                ],
            },
        },
    };

}



