import { Hardcode } from './../_helpers/hardcode-handle';
import { Injectable, OnDestroy } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { ServerUrl, AppErrorHandler, AppConfig, Constants } from '../_helpers';
import { DataService } from '../_helpers/data-service-handle';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from './common.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WebSocketSubject, webSocket } from 'rxjs/internal-compatibility';
import { Router, ActivationEnd } from '@angular/router';
import { filter, first, delay } from 'rxjs/operators';

@Injectable()
export class DrawService implements OnDestroy {
  // private subjectUserDataParent = new BehaviorSubject({});
  // private subjectUserDataChild = new BehaviorSubject({});
  // userDataObservableParent = this.subjectUserDataParent.asObservable();
  // userDataObservableChild = this.subjectUserDataChild.asObservable();
  // private gameDataObserver = new BehaviorSubject(null);
  private gameDataObserver = new Subject<any>();
  private playerTicketsObserver = new Subject<any>();
  private subjectUserDataParent = new Subject<any>();
  private subjectUserDataChild = new Subject<any>();
  private subjectAdvDrawPopupStatus = new Subject<any>();
  private subjectAdvDrawPopupSubmit = new Subject<any>();
  private subjectPanelData = new Subject<any>();
  private subjectOpenResultPopup = new Subject<any>();
  private subjectOpenHelpPopup = new Subject<any>();
  private subjectPayoutPanelStatus = new Subject<any>();
  private subjectLoaderStatus = new Subject<any>();
  private subjectTicketStatus = new Subject<any>();
  private subjectResetUserData = new Subject<any>();
  private subjectUpdateUserData = new Subject<any>();
  private subjectPlayResetUserData = new Subject<any>();
  private subjectPanelCounter = new Subject<any>();
  private subjectPlayerTicketData = new Subject<any>();
  private subjectTicketList = new Subject<any>();
  private subjectTrackTicket = new Subject<any>();
  private subjectBuyPurchasePanel = new Subject<any>();
  private subjectConversionPixel = new Subject<any>();
  private purchaseTicketResponse: any;
  private cancelTicketResponse: any;
  private claimTicketResponse: any;
  isMobile = false;
  static gameTime = 0;
  static gameTimeInterval: any;
  static fetchGameCounter = 0;
  orderingTicketList = {
    gameCode: '',
    orderBy: '',
    currentTab: 1
  };
  fromMyTickets = false;
  fromLobby = false;
  fromDrawMachine = true;
  private socket$: WebSocketSubject<any>;
  private socketInterval$: any;
  private routeSubscription: any;

  constructor(
    private dataservice: DataService,
    private alertService: AlertService,
    private commonService: CommonService,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService,
    private router: Router,
  ) {
    // let deviceInfo = this.deviceService.getDeviceInfo();
    let isDesktopDevice = this.deviceService.isDesktop();
    this.isMobile = (this.deviceService.isMobile() || this.deviceService.isTablet()) ? true : false;
    this.routeSubscription = router.events.pipe(
      filter(e => e instanceof ActivationEnd),
      first()
    ).subscribe(val => {
      let tempVar = <any>val;
      let params = tempVar.snapshot.params;
      if (params.isMobileApp == '1') {
        this.isMobile = true;
      }
      this.commonService.iframe_resize();
    });
  }

  ngOnDestroy(): void {
    clearInterval(DrawService.gameTimeInterval);
    if (typeof this.routeSubscription != 'undefined') {
      this.routeSubscription.unsubscribe();
    }
  }

  subscribeGameData(): Observable<any> {
    return this.gameDataObserver.asObservable();
  }

  subscribePlayerTicketsData(): Observable<any> {
    return this.playerTicketsObserver.asObservable();
  }

  subscribeUserDataParent(): Observable<any> {
    return this.subjectUserDataParent.asObservable();
  }

  subscribeUserDataChild(): Observable<any> {
    return this.subjectUserDataChild.asObservable();
  }

  subscribeAdvDrawPopupSubmit(): Observable<any> {
    return this.subjectAdvDrawPopupSubmit.asObservable();
  }

  subscribeAdvDrawPopupStatus(): Observable<any> {
    return this.subjectAdvDrawPopupStatus.asObservable();
  }

  subscribePayoutPanelStatus(): Observable<any> {
    return this.subjectPayoutPanelStatus.asObservable();
  }

  subscribeOpenResultPopup(): Observable<any> {
    return this.subjectOpenResultPopup.asObservable();
  }

  subscribeOpenHelpPopup(): Observable<any> {
    return this.subjectOpenHelpPopup.asObservable();
  }

  subscribePanelData(): Observable<any> {
    return this.subjectPanelData.asObservable();
  }

  subscribeLoaderStatus(): Observable<any> {
    return this.subjectLoaderStatus.asObservable();
  }

  subscribeTicketStatus(): Observable<any> {
    return this.subjectTicketStatus.asObservable();
  }

  subscribeResetUserData(): Observable<any> {
    return this.subjectResetUserData.asObservable();
  }

  subscribeResetPlayUserData(): Observable<any> {
    return this.subjectPlayResetUserData.asObservable();
  }

  subscribeUpdateUserData(): Observable<any> {
    return this.subjectUpdateUserData.asObservable();
  }

  subscribePanelCounter(): Observable<any> {
    return this.subjectPanelCounter.asObservable();
  }

  subscribePlayerTicketData(): Observable<any> {
    return this.subjectPlayerTicketData.asObservable();
  }

  subscribeTicketList(): Observable<any> {
    return this.subjectTicketList.asObservable();
  }

  subscribeTrackTicket(): Observable<any> {
    return this.subjectTrackTicket.asObservable();
  }

  subscribeBuyNotification(): Observable<any> {
    return this.subjectBuyPurchasePanel.asObservable();
  }

  subscribeConversionPixel(): Observable<any> {
    return this.subjectConversionPixel.asObservable();
  }

  subscribeSocketData(): Observable<any> {
    if (!this.socket$) {
      this.socket$ = webSocket({
        url: ServerUrl.WEBSOCKET_DOMAIN,
        serializer: (msg) => {
          let tempMsg = JSON.stringify(msg);
          // console.log('Message Sent: ' + tempMsg);
          return tempMsg;
        },
        deserializer: ({ data }) => {
          let tempdata;
          try {
            tempdata = JSON.parse(JSON.parse(data));
          } catch (e) {
            if (data == '') {
              tempdata = JSON.parse('{}');
            } else {
              tempdata = JSON.parse(data);
            }
          }
          // console.log("Message Received: " + JSON.stringify(tempdata));
          return tempdata;
        },
        openObserver: {
          next: () => {
            console.warn('Draw Machine Socket Connection Established!');
            this.socketInterval$ = setInterval(() => {
              this.socketPing();
            }, 60000);
          }
        },
        closeObserver: {
          next(closeEvent) {
            console.warn('Draw Machine Socket Connection Closed!');
            clearInterval(this.socketInterval$);
          }
        }
      });
    }
    return this.socket$.asObservable();
  }

  changeUserDataParent(userData: any, panelCounter: number, reset: boolean) {
    this.subjectUserDataParent.next({ panelCounter: panelCounter, userData: userData, reset: reset })
  }

  changeUserDataChild(userData: any, panelCounter: number, reset: boolean, blocker: boolean) {
    this.subjectUserDataChild.next({ panelCounter: panelCounter, userData: userData, reset: reset, blocker: blocker })
  }

  getGameData = (gameType = Constants.DRAW_ENGINE, timerType = '') => {
    // return new Observable((observer) => {
    let response: any;
    let activeGames = [];
    let tempUser: any = {};
    tempUser = JSON.parse(localStorage.getItem('drawGameUserInfo'));
    if (timerType == '') {
      if (gameType == Constants.DRAW_ENGINE) {
        // localStorage.removeItem('drawGameData');                                          //because the multiple currency occured the issue
        response = JSON.parse(localStorage.getItem('drawGameData'));
      } else {
        // localStorage.removeItem('bingoGameData');                                         //because the multiple currency occured the issue.
        response = JSON.parse(localStorage.getItem('bingoGameData'));
      }
      activeGames = Constants.CLIENT_GAMES_MAPPING[Constants.CLIENT_CODE][gameType];
      if (tempUser.aliasName == "www.winlot.in" && Constants.CLIENT_PLAYERWISE_GAMES_MAPPING[tempUser.playerid]) {          // It's only use for differnet client on winlott for lobby ....                        
        let playerWiseGameEngine = Constants.CLIENT_PLAYERWISE_GAMES_MAPPING[tempUser.playerid].clientWiseGameEngine;
        activeGames = Constants.CLIENT_GAMES_MAPPING[Constants.CLIENT_CODE][playerWiseGameEngine];
      }
    } else {
      if (gameType == Constants.DRAW_ENGINE) {
        // localStorage.removeItem(timerType+'-drawGameData');                             //because the multiple currency occured the issue
        response = JSON.parse(localStorage.getItem(timerType + '-drawGameData'));
      } else {
        // localStorage.removeItem(timerType+'-bingoGameData');                              //because the multiple currency occured the issue.....
        response = JSON.parse(localStorage.getItem(timerType + '-bingoGameData'));
      }
      activeGames.push(timerType);
    }
    let tempGameRespVOsArray = (((response || {}).data || {}).responseData || {}).gameRespVOs || [];
    let tempCacheDisabled = tempGameRespVOsArray.length == 0 ? '' : tempGameRespVOsArray[0].betLimitEnabled;
    if (DrawService.fetchGameCounter == 0 || response == null || (timerType != '' && tempCacheDisabled == 'YES') || Constants.CLIENT_MULTI_CURRENCY[Constants.CLIENT_CODE]) {
      this.returnFetchDataResponse(gameType, timerType, activeGames).subscribe(response => {
        this.gameDataObserver.next(response);
        // observer.next(response);
      });
    } else {
      // let dateCurrent = new Date();
      // let dateCurrent = new Date(response.data.responseData.currentDate.replace(/-/g, "/"));
      var reset = false;
      if (tempUser.aliasName == "www.winlot.in" && Constants.CLIENT_PLAYERWISE_GAMES_MAPPING[tempUser.playerid]) {               // It's only use for differnet client on winlott for lobby ....       
        let playerWiseGameEngine = Constants.CLIENT_PLAYERWISE_GAMES_MAPPING[tempUser.playerid].clientWiseGameEngine;
        activeGames = Constants.CLIENT_GAMES_MAPPING[Constants.CLIENT_CODE][playerWiseGameEngine];
      }
      for (var i = 0; i < response.data.responseData.gameRespVOs.length; i++) {
        var element = response.data.responseData.gameRespVOs[i];
        let gameDate = new Date(this.changeDateFormat(element.timeToFetchUpdatedGameInfo));
        // let diff = Math.abs(Math.abs(gameDate.getTime() - dateCurrent.getTime()) / 1000);
        // let diff = Math.abs(Math.abs(gameDate.getTime() - dateCurrent.getTime()) / 1000);
        let diff = Math.abs(Math.abs(gameDate.getTime() - DrawService.gameTime) / 1000)
        if (diff <= 0) {
          this.returnFetchDataResponse(gameType, timerType, activeGames).subscribe(response => {
            this.gameDataObserver.next(response);
            // observer.next(response);
            reset = true;
          });
          break;
        }
      }
      if (reset == false) {
        response.timerType = timerType;
        this.gameDataObserver.next(response);
        // observer.next(response);
      }
    }
    // });
  }

  setAdvDrawPopupStatus = (status = true, userData = {}) => {
    this.subjectAdvDrawPopupStatus.next({ status: status, userData: userData });
  }

  setPayoutPanelStatus = (status = true) => {
    this.subjectPayoutPanelStatus.next({ status: status });
  }

  openResultPopup = (status = true) => {
    this.subjectOpenResultPopup.next({ status: status });
  }

  openHelpPopup = (status = true, gameCode) => {
    this.subjectOpenHelpPopup.next({ status: status, gameCode: gameCode });
  }

  setAdvDrawPopupSubmit = (userData = {}) => {
    this.subjectAdvDrawPopupSubmit.next({ userData: userData });
  }

  setPanelData = (panelData = {}) => {
    this.subjectPanelData.next({ panelData: panelData });
  }

  setLoaderStatus = (status = true) => {
    this.subjectLoaderStatus.next({ status: status });
  }

  setTicketStatus = (status = true, ticketData = {}) => {
    this.subjectTicketStatus.next({ status: status, ticketData: ticketData });
  }

  setPanelCounter = (panelCounter = 0, totalPrice = 0) => {
    this.subjectPanelCounter.next({ panelCounter: panelCounter, totalPrice: totalPrice });
  }

  resetUserData = (status = true) => {
    this.subjectResetUserData.next({ status: status });
  }

  updateUserData = (userData = {}) => {
    this.subjectUpdateUserData.next({ userData: userData });
  }

  resetPlayUserData = (status = true) => {
    this.subjectPlayResetUserData.next({ status: status });
  }

  updateTicketList = (status = true) => {
    this.subjectTicketList.next({ status: status })
  }

  notifyBuyPurchasePanel = (status = true) => {
    this.subjectBuyPurchasePanel.next({ status: status })
  }

  notifyConversionPixel = (status = false, amount = 0) => {
    this.subjectConversionPixel.next({ status: status, amount: amount })
  }

  returnFetchDataResponse(gameType = Constants.DRAW_ENGINE, timerType = '', activeGames = []) {
    return this.dataservice.postData(ServerUrl.DRAW_GET_GAMEDATA, { gameCode: activeGames, timerType: timerType, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        // response = Hardcode.DRAW_FETCH_GAME_DATA;
        ++DrawService.fetchGameCounter;
        response.timerType = timerType;
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          response.data.responseData.gameRespVOs.sort((a, b) => {
            let keyA = parseInt(a.displayOrder);
            let keyB = parseInt(b.displayOrder);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          if (timerType == '') {
            if (gameType == Constants.DRAW_ENGINE) {
              localStorage.setItem('drawGameData', JSON.stringify(response));
            } else {
              localStorage.setItem('bingoGameData', JSON.stringify(response));
            }
          } else {
            if (gameType == Constants.DRAW_ENGINE) {
              localStorage.setItem(timerType + '-drawGameData', JSON.stringify(response));
            } else {
              localStorage.setItem(timerType + '-bingoGameData', JSON.stringify(response));
            }
          }
          clearInterval(DrawService.gameTimeInterval);
          DrawService.gameTime = new Date(this.changeDateFormat(response.data.responseData.currentDate)).getTime();
          DrawService.gameTimeInterval = setInterval(() => {
            DrawService.gameTime += 1000;
          }, 1000);
          return response;
        } else {
          if (response.responseCode == 1000 || response.responseCode == 12345) {
            return response;
          } else {
            this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
          }
        }
        // return response;
      }));
  }

  getPrizeScheme(gameCode) {
    return this.dataservice.postData(ServerUrl.DRAW_GET_PRIZE_SCHEME, { 'gameCode': gameCode, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        // response = Hardcode.DRAW_GET_PRIZE_SCHEME;
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
        }
        // return response;
      }));
  }

  getQuickPickData({ 'gameCode': gameCode, 'noOfLines': noOfLines, 'numbersPicked': numbersPicked }) {
    return this.dataservice.postData(ServerUrl.DRAW_GET_QUICK_PICK, { 'gameCode': gameCode, 'noOfLines': noOfLines, 'numbersPicked': numbersPicked, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        // response = Hardcode.DRAW_GET_QUICK_PICK;
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
        }
        // return response;
      }));
  }

  ticketPurchase(ticketData) {
    ticketData.service = AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service;
    ticketData.isMobile = this.isMobile;
    return this.dataservice.postData(ServerUrl.DRAW_TICKET_SALE, ticketData, { withCredentials: true })
      .pipe(
        tap((response: any) => {
          this.purchaseTicketResponse = response;
          // this.purchaseTicketResponse = Hardcode.DRAW_TICKET_SALE;
          this.purchaseTicketResponse.responseType = 'purchase';
          localStorage.setItem('saleData', JSON.stringify(response));
        }),
        switchMap((response: any) => {
          // response = this.purchaseTicketResponse;
          if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
            this.purchaseTicketResponse.data.responseData.userName = this.commonService.getPlayerData().username;
            if (AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].print_enable) {
              return this.printTicket(this.purchaseTicketResponse);
            } else {
              return new Observable((observer) => {
                observer.next(this.purchaseTicketResponse);
              });
            }
          } else {
            if (response.responseCode == 2000 || response.responseCode == 2018) {
              let tempNumber = (response.responseMessage.replace(/[^0-9,]/g, '').split(',')).join(', ');
              this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`, { number: tempNumber }));
            } else if (response.responseCode == 1019) {
              this.alertService.info(this.translate.instant(`error.dge.${response.responseCode}`));
            } else if (![2012, 2014].includes(response.responseCode)) {
              this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
            }
            return new Observable((observer) => {
              observer.next(response);
            });
          }
        }),
        switchMap((response2: any) => {
          if (typeof response2 != 'undefined' && (typeof response2.responseCode != 'undefined' || response2.success == true)) {
            return new Observable((observer) => {
              observer.next(this.purchaseTicketResponse);
            });
          } else {
            return this.cancelTicket({ 'type': 'inner' });
          }
        }),
        switchMap((response3: any) => {
          if (typeof response3 != 'undefined' && typeof response3.data != 'undefined' && response3.data.responseCode == 0 && response3.responseType == 'cancel') {
            this.alertService.error("Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not. \nLast ticket has been cancelled!!");
            return new Observable((observer) => {
              observer.next({ success: false, responseMessage: "Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not. \nLast ticket has been cancelled!!" });
            });
          } else if (response3.responseType == 'purchase') {
            return new Observable((observer) => {
              observer.next(this.purchaseTicketResponse);
            });
          } else {
            this.alertService.error("Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not. \nTicket has been purchased!!");
            return new Observable((observer) => {
              observer.next(this.purchaseTicketResponse);
            });
          }
        })
      );
  }

  cancelTicket({ 'type': type }) {
    return this.dataservice.postData(ServerUrl.DRAW_TICKET_CANCEL, { service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(
        tap((response: any) => {
          this.cancelTicketResponse = response;
          this.cancelTicketResponse.responseType = 'cancel';
        }),
        switchMap((response: any) => {
          if (type == 'inner') {
            return new Observable((observer) => {
              observer.next(this.cancelTicketResponse);
            });
          } else {
            if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
              if (AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].print_enable) {
                return this.printCancelTicket(this.cancelTicketResponse);
              } else {
                return new Observable((observer) => {
                  observer.next(this.cancelTicketResponse);
                });
              }
            } else {
              this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
              return new Observable((observer) => {
                observer.next({ success: false, responseMessage: response.responseMessage, responseType: 'cancel' });
              });
            }
          }
        }),
        switchMap((response2: any) => {
          if (typeof response2 != 'undefined' && response2.success == true) {
            return new Observable((observer) => {
              observer.next(this.cancelTicketResponse);
            });
          } else if (response2.success == false && typeof response2.responseType != 'undefined' && response2.responseType == 'cancel') {
            this.alertService.error(this.translate.instant(`error.dge.${response2.responseCode}`));
            return new Observable((observer) => {
              observer.next(response2);
            });
          } else {
            this.alertService.error("Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not. \nTicket has been cancelled!!");
            return new Observable((observer) => {
              observer.next({ success: false, responseMessage: "Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not. \nTicket has been cancelled!!" });
            });
          }
        }),
      );
  }

  rePrintTicket() {
    return this.dataservice.postData(ServerUrl.DRAW_TICKET_REPRINT, { service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          response.data.responseData.userName = this.commonService.getPlayerData().username;
          if (AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].print_enable) {
            this.printTicket(response).subscribe((response2: any) => {
              if (typeof response2 != 'undefined' && response2.success == true) {
                return response;
              } else {
                this.alertService.error("Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not!!");
                return response;
              }
            });
          }
        } else {
          this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
        }
        // return response;
      }));
  }

  verifyTicket(ticketNumber) {
    return this.dataservice.postData(ServerUrl.DRAW_VERIFY_TICKET, { 'ticketNumber': ticketNumber, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
          throw new AppErrorHandler(response);
        }
        // return response;
      }));
  }

  claimWin(ticketNumber) {
    return this.dataservice.postData(ServerUrl.DRAW_CLAIM_WIN, { 'ticketNumber': ticketNumber, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(
        tap((response: any) => {
          this.claimTicketResponse = response;
          // this.claimTicketResponse = Hardcode.WINNING_CLAIM;
          this.claimTicketResponse.responseType = 'claim';
        }),
        switchMap((response: any) => {
          // response = this.claimTicketResponse;
          if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
            this.claimTicketResponse.data.responseData.userName = this.commonService.getPlayerData().username;
            if (AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].print_enable) {
              return this.printClaimWin(this.claimTicketResponse);
            } else {
              return new Observable((observer) => {
                observer.next(this.claimTicketResponse);
              });
            }
          } else {
            this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
            return new Observable((observer) => {
              observer.next({ success: false, responseMessage: response.responseMessage, responseType: 'ticket' });
            });
            // throw new AppErrorHandler(response);
          }
        }),
        switchMap((response2: any) => {
          if (typeof response2 != 'undefined' && response2.success == true) {
            return new Observable((observer) => {
              observer.next(this.claimTicketResponse);
            });
          } else {
            this.alertService.error("Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not!!");
            return new Observable((observer) => {
              observer.next(this.claimTicketResponse);
            });
          }
        }),
      );
  }

  printTicket(ticketData) {
    ticketData.service = AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service;
    ticketData.isMobile = this.isMobile;
    return this.dataservice.postData(ServerUrl.DRAW_TICKET_PRINT_PURCHASE, ticketData, { withCredentials: true })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  printCancelTicket(ticketData) {
    ticketData.service = AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service;
    ticketData.isMobile = this.isMobile;
    return this.dataservice.postData(ServerUrl.DRAW_TICKET_PRINT_CANCEL, ticketData, { withCredentials: true })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  printClaimWin(ticketData) {
    ticketData.service = AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service;
    ticketData.isMobile = this.isMobile;
    return this.dataservice.postData(ServerUrl.DRAW_TICKET_PRINT_CLAIM_WIN, ticketData, { withCredentials: true })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  printDummy() {
    if (AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].print_enable) {
      return this.dataservice.postData(ServerUrl.DRAW_TICKET_PRINT_DUMMY, { service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
        .pipe(map((response: any) => {
          if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
            return response;
          } else {
            this.alertService.error("Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not!!");
          }
        }));
    } else {
      return new Observable((observer) => {
        observer.next({ success: false });
      });
    }
  }

  printResult(ticketData) {
    ticketData.service = AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service;
    ticketData.isMobile = this.isMobile;
    if (AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].print_enable) {
      return this.dataservice.postData(ServerUrl.DRAW_TICKET_PRINT_RESULT, ticketData, { withCredentials: true })
        .pipe(map((response: any) => {
          if (typeof response != 'undefined' && response.success == true) {
            return response;
          } else {
            this.alertService.error("Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not!!");
          }
        }));
    } else {
      return new Observable((observer) => {
        observer.next({ success: false });
      });
    }
  }

  getTrackTicket(ticketNumber, txnId) {
    this.trackTicket(ticketNumber, txnId).subscribe(response => {
      this.subjectTrackTicket.next(response);
    });
  }

  trackTicket(ticketNumber, txnId) {
    return this.dataservice.postData(ServerUrl.DRAW_TRACK_TICKET, { ticketNumber: ticketNumber, merchantTxnId: txnId, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        // response = Hardcode.TRACK_TICKET;
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
        }
      }));
  }

  getDrawResults({ 'gameCode': gameCode, 'fromDate': fromDate, 'toDate': toDate, "orderByOperator": orderByOperator, "orderByType": orderByType, "page": page, "size": size }) {
    return this.dataservice.postData(ServerUrl.DRAW_GET_RESULTS, { 'gameCode': gameCode, 'fromDate': fromDate, 'toDate': toDate, "orderByOperator": orderByOperator, "orderByType": orderByType, "page": page, "size": size, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        // response = Hardcode.RESULTS;
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
          // throw new AppErrorHandler(response);
        }
        // return response;
      }));
  }

  fetchTicketStatus(ticketList) {
    return this.dataservice.postData(ServerUrl.DRAW_FETCH_TICKET_STATUS, { merchantTxnIdList: ticketList, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        // response = Hardcode.TICKET_STATUS;
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
        }
      }));
  }

  fetchPlayerTicketList(params) {
    return this.dataservice.postData(ServerUrl.DRAW_FETCH_PLAYER_TICKET_LIST, { "drawId": ((typeof params.drawId != 'undefined') ? params.drawId : 0), "gameCode": params.gameCode, "orderBy": params.orderBy, "pageSize": params.pageSize, "pageIndex": params.pageIndex, "isBingoEnable": params.isBingoEnable, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        // response = Hardcode.PLAYER_TICKET_LIST;
        if (typeof response.data != 'undefined') {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
        }
      }));
  }

  qpGenerator({ numberConfig: numberConfig, numbersPicked: numbersPicked, noOfLines: noOfLines }) {
    let tempArray = [];
    for (let i = 0; i < numbersPicked; i++) {
      do {
        let tempNum = numberConfig[Math.floor((Math.random() * numberConfig.length))].number;
        if (tempArray.indexOf(tempNum) < 0) {
          tempArray.push(tempNum);
          break;
        }
      } while (true);
    }
    return tempArray;
  }

  getCardDetails({ "status": status, "page": page, "size": size }) {
    return this.dataservice.postData(ServerUrl.DRAW_FETCH_CARD_LIST, { "status": status, "page": page, "size": size, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service }, { withCredentials: true })
      .pipe(map((response: any) => {
        // response = Hardcode.RESULTS;
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
          // throw new AppErrorHandler(response);
        }
        // return response;
      }));
  }

  getWinnerList({ "drawId": drawId, "gameId": gameId }) {
    return this.dataservice.postData(ServerUrl.DRAW_WINNER_LIST, { "drawId": drawId, "gameId": gameId, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service }, { withCredentials: true })
      .pipe(map((response: any) => {
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
          // throw new AppErrorHandler(response);
        }
        // return response;
      }));
  }

  getPanelWiseRank({ "gameCode": gameCode, "ticketNumber": ticketNumber }) {
    return this.dataservice.postData(ServerUrl.DRAW_PANELWISE_RANK, { "gameCode": gameCode, "ticketNumber": ticketNumber, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service }, { withCredentials: true })
      .pipe(map((response: any) => {
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
          // throw new AppErrorHandler(response);
        }
        // return response;
      }));
  }

  changeDateFormat(date, joinParam = '/', reverse = false) {
    /* "dd-mm-YYYY HH:ii:ss" to "YYYY-mm-dd HH:ii:ss" */
    if (reverse) {
      let tempArray = date.replace(/-/gi, "/").split('.')[0].split(" ");
      tempArray[0] = tempArray[0].split('/').reverse().join('/');
      return tempArray.join(" ");
    } else {
      return date.replace(/-/gi, "/").split('.')[0];
    }
  }

  socketLogin() {
    let tmpVar = { "cmd": "login", "isAdmin": false, "name": Constants.WEBSOCKET_USER, "password": Constants.WEBSOCKET_PSWD };
    this.socket$.next(tmpVar);
  }

  socketGatData(gameId) {
    let tmpVar = { "cmd": "getData", "gameId": gameId };
    this.socket$.next(tmpVar);
  }

  socketPing() {
    let tmpVar = { "cmd": "ping", "msg": "ping" };
    this.socket$.next(tmpVar);
  }

  closeSocket() {
    this.socket$.complete();
    // this.socket$.error({ code: 4000, reason: 'I think our app just broke!' });
  }

  generateRandomNumber() {
    var arr = new Uint32Array(1);
    crypto.getRandomValues(arr);
    return (arr[0] * Math.pow(2, -32));
  }
}


                 