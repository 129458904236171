import { Hardcode } from '../_helpers/hardcode-handle';
import { Injectable, OnDestroy } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { ServerUrl, AppErrorHandler, AppConfig, Constants } from '../_helpers';
import { DataService } from '../_helpers/data-service-handle';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from './common.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Sge2Service implements OnDestroy {

  private gameListObserver = new Subject<any>();
  private sge2GameObserver = new Subject<any>();
  private purchaseTicketResponse: any;

  static gameTime = 0;
  static gameTimeInterval: any;
  static fetchGameCounter = 0;
  isMobile = false;

  orderingTicketList = {
    gameCode: '',
    orderBy: ''
  };
  fromMyTickets = false;

  constructor(
    private dataservice: DataService,
    private alertService: AlertService,
    private commonService: CommonService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService) {
    this.isMobile = (this.deviceService.isMobile() || this.deviceService.isTablet()) ? true : false;
  }

  ngOnDestroy(): void {
    clearInterval(Sge2Service.gameTimeInterval);
  }

  subscribeGameList(): Observable<any> {
    return this.gameListObserver.asObservable();
  }

  subcribeSGE2game(): Observable<any> {
    return this.sge2GameObserver.asObservable();
  }

  getGameList = (lobbyCode = '', timerType = '') => {
    let response = JSON.parse(localStorage.getItem('sge2GameData'));
    if (response == null || Sge2Service.fetchGameCounter == 0) {
      this.returnSLEDrawDataResponse(lobbyCode, timerType).subscribe(response => {
        this.gameListObserver.next(response);
      });
    } else {
      // var reset = false;
      // for (var i = 0; i < response.data.sleData.gameData[0].gameTypeData.length; i++) {
      //   var element = response.data.sleData.gameData[0].gameTypeData[i];
      //   if (element.drawData != 0) {
      //     let drawFreezeTime = this.changeDateFormat(response.data.sleData.gameData[0].gameTypeData[i].drawData[0].ftg);
      //     let gameDate = new Date(drawFreezeTime);
      //     let diff = Math.abs(Math.abs(gameDate.getTime() - Sge2Service.gameTime) / 1000);
      //     if (diff <= 0) {
      //       this.returnSLEDrawDataResponse(timerType).subscribe(response => {
      //         this.gameListObserver.next(response);
      //         reset = true;
      //       });
      //       break;
      //     }
      //   }
      // }
      // if (reset == false) {
      //   response.timerType = timerType;
      //   this.gameListObserver.next(response);
      // }
    }
  }

  getGameURL = (request) => {
    this.fetchGameURL(request).subscribe(response => {
      this.sge2GameObserver.next(response);
    });
  }

  fetchGameURL(request) {
    request.service = AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service;
    request.isMobile = this.isMobile;
    return this.dataservice.postData(ServerUrl.SGE2_GAME_URL, request, { withCredentials: true })
      .pipe(map((response: any) => {
        if (typeof response.data != 'undefined' && (response.data.status == 200)) {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.sge2.${response.data.status}`));
        }
      }));
  }

  returnSLEDrawDataResponse(lobbyCode = '', timerType = '') {
    return this.dataservice.postData(ServerUrl.SGE2_GAMELIST, { lobbyCode: lobbyCode, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        if (typeof response.data != 'undefined') {
          localStorage.setItem('sge2GameData', JSON.stringify(response));
          return response;
        } else {
          if (response.responseCode == 1000) {
            return response;
          } else {
            this.alertService.error(response.responseMessage);
          }
        }
      }));
  }

}