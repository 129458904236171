import { Routes, RouterModule } from '@angular/router';
// import { ModuleWithProviders } from '@angular/core';
import { AuthGuard, AuthGuardLogin, AuthGuardEnable } from './_guards';
import { Constants } from './_helpers';

const appRoutes: Routes = [
    { path: 'dge', loadChildren: () => import('./draw/draw.module').then(m => m.DrawModule), canActivate: [AuthGuardEnable], data: { engine: ['dge'] } },
    { path: 'sle', loadChildren: () => import('./sle/sle.module').then(m => m.SleModule), canActivate: [AuthGuardEnable], data: { engine: ['sle'] } },
    { path: 'bigboss', loadChildren: () => import('./bigboss/bigboss.module').then(m => m.BigBossModule), canActivate: [AuthGuardEnable], data: { engine: ['big_boss'] } },
    { path: 'bingo', loadChildren: () => import('./bingo/bingo.module').then(m => m.BingoModule), canActivate: [AuthGuardEnable], data: { engine: ['bingo'] } },
    { path: 'ige', loadChildren: () => import('./ige/ige.module').then(m => m.IgeModule), canActivate: [AuthGuardEnable], data: { engine: ['ige'] } },
    { path: 'sge', loadChildren: () => import('./sge/sge.module').then(m => m.SgeModule), canActivate: [AuthGuardEnable], data: { engine: ['sge'] } },
    { path: 'sge2', loadChildren: () => import('./sge2/sge2.module').then(m => m.Sge2Module), canActivate: [AuthGuardEnable], data: { engine: ['sge2'] } },
    { path: 'cap', loadChildren: () => import('./cap/cap.module').then(m => m.CapModule), canActivate: [AuthGuardEnable], data: { engine: ['cap'] } },
    // otherwise redirect to home
    { path: '**', redirectTo: Constants.CLIENT_GAMES.default }
];

export const routing = RouterModule.forRoot(appRoutes);
// export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, { useHash: false, onSameUrlNavigation: 'reload' });