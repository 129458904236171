import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { AuthGuard, AuthGuardLogin, AuthGuardEnable } from './_guards';
import { AuthenticationService, UserService, AlertService, ResultService, CommonService, LoaderService, SessionService } from './_services';
import { HomeComponent } from './home';
// import { AppErrorHandler } from './_helpers';
import { HttpErrorInterceptor, DEFAULT_TIMEOUT } from './_interceptors';
import { SimilarModule } from './_common_component/similar.module';
import { LoaderComponent } from "./_common_component/loader";
// import { DomChangeDirective } from './_directives';
import { NgxPaginationModule } from 'ngx-pagination';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Constants } from './_helpers';
import { NgxBarcodeModule } from 'ngx-barcode';
@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    SimilarModule,
    routing,
    NgxPaginationModule,
    NgxBarcodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DeviceDetectorModule.forRoot()
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoaderComponent,
    // DomChangeDirective
  ],
  providers: [
    AuthGuard,
    AuthGuardLogin,
    AuthGuardEnable,
    AlertService,
    ResultService,
    AuthenticationService,
    UserService,
    CommonService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_TIMEOUT,
      useValue: 25000
    },
    {
      provide: LOCALE_ID,
      // useValue: 'fr-FR'
      deps: [SessionService],
      useFactory: (sessionService) => sessionService.locale
    }
    // {provide: ErrorHandler, useClass: AppErrorHandler}
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/' + Constants.CLIENT_CODE + '/', '.json');
}