import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import * as io from 'socket.io-client';
import { ServerUrl } from '../_helpers';

@Injectable()

export class ChatService {

	private socket: any;
	private username: any;
	private connected: boolean = false;
	CurrentTime: string;

	constructor() { }

	connect() {
		this.disconnect();
		this.socket = io(ServerUrl.SOCKETIO_ENDPOINT);
		this.connected = true;
	}

	isConnected() {
		return this.connected;
	}

	disconnect() {
		try {
			if (this.socket != null && this.connected == true) {
				this.socket.disconnct();
				this.connected = false;
			}
		}
		catch (e) {

		}
	}

	serverSendMessage(data) {
		this.socket.emit('message', data);
	}

	serverAddUser(username) {
        this.username = username;
        this.socket.emit('add user', username);
    }

    serverJoinRoom(data) {
		this.socket.emit('join room', data);
	}

	joinRoom(data) {
		this.socket.emit('join', data);
	}

	serverRoomList() {
		this.socket.emit('room list');
	}

	leaveRoom(data) {
		this.socket.emit('leave', data);
	}

	newMessageReceived() {
		let observable = new Observable<{ state: String, user: String, message: String }>(observer => {
			this.socket.on('new message', (data) => {
				this.CurrentTime = this.addZero(new Date().getHours()) + ':' + this.addZero(new Date().getMinutes());
				data.time = this.CurrentTime;
				observer.next(data);

			});
			return () => { this.socket.disconnect(); }
		});
		return observable;
	}

	userCount() {
		let observable = new Observable(observer => {
			this.socket.on('show room list', (data: any) => {
				observer.next(data);
			});
			return () => { this.socket.disconnect(); }
		});
		return observable;
	}

	newUserJoined() {
		let observable = new Observable<{ user: String, message: String }>(observer => {
			this.socket.on('new user joined', (data) => {
				data.state = 'join';
				observer.next(data);
			});
			return () => { this.socket.disconnect(); }
		});
		return observable;
	}

	userLeftRoom() {
		let observable = new Observable<{ user: String, message: String }>(observer => {
			this.socket.on('left room', (data) => {
				data.state = 'left';
				observer.next(data);
			});
			return () => { this.socket.disconnect(); }
		});
		return observable;
	}

	connectedUser() {
		let observable = new Observable<{ user: any }>(observer => {
			this.socket.on('connected', (data) => {
				observer.next(data);
			});
			return () => { this.socket.disconnect(); }
		});
		return observable;
	}

	resetChat() {
		localStorage.removeItem('messageArray');
	}

	addZero(i) {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	// playerInfo() {
    //     let observable = new Observable(observer => {
    //         this.socket.on('user joined', (data: any) => {
    //             observer.next(data);
    //         });
    //         return () => { this.socket.disconnect(); }
    //     });
    //     return observable;
    // }

}