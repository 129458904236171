import { Injectable } from '@angular/core';
import { AppConfig, ServerUrl, DataService, Constants } from '../_helpers';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

declare var goToHome: any;
declare var showLoginDialog: any;
declare var onBalanceUpdate: any;
declare var loadInstantGameUrl: any;
declare var reloadGame: any;
declare var showDepositScreen: any;
declare var getWindowDimensions: any;
declare var Mobile: any;
declare var JSInterface: any;
declare var webkit: any;
declare var sendSaleData: any;
@Injectable()
export class CommonService {

  private offCanvasTogglerParent = new Subject<any>();
  private modalCloseSubject = new Subject<any>();

  timeOutVar: any;
  timeIntervalVar: any;
  browserSupprted = false;
  iframeTopHeight = 0;
  targetElement: any;
  // yScrollposition = 0;

  constructor(private dataservice: DataService,) { }

  getPlayerData() {
    if (AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service == 'B2B') {
      var playerData = localStorage.getItem('userData');
      return JSON.parse(playerData);
    } else {
      var playerData = localStorage.getItem('drawGameUserInfo');
      return JSON.parse(playerData);
    }
  }

  subscribeOffCanvasTogglerParent(): Observable<any> {
    return this.offCanvasTogglerParent.asObservable();
  }

  subscribeModalClose(): Observable<any> {
    return this.modalCloseSubject.asObservable();
  }

  closeAllModals() {
    this.modalCloseSubject.next({ modalStatus: false });
  }

  changeUserDataParent() {
    this.offCanvasTogglerParent.next({ toggle: true })
  }

  iframe_resize() {
    if (typeof Mobile != 'undefined' && typeof Mobile.iframe_resize == "function") {
      Mobile.iframe_resize();
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.iframe_resize != 'undefined') {
      webkit.messageHandlers.iframe_resize.postMessage("event from Angular Iframe");
    } else {
      window.parent.postMessage(document.getElementsByClassName('bodyWrapper')[0].clientHeight, "*");
    }
    // clearTimeout(this.timeOutVar);
    // this.timeOutVar = setTimeout(() => {
    //   if (typeof Mobile != 'undefined' && typeof Mobile.iframe_resize == "function") {
    //     Mobile.iframe_resize();
    //   } if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.iframe_resize != 'undefined') {
    //     webkit.messageHandlers.iframe_resize.postMessage("event from Angular Iframe");
    //   } else {
    //     window.parent.postMessage(document.getElementsByClassName('bodyWrapper')[0].clientHeight, "*");
    //   }
    //   clearTimeout(this.timeOutVar);
    // }, 100);
    clearTimeout(this.timeOutVar);
    clearInterval(this.timeIntervalVar);
    this.timeIntervalVar = setInterval(() => {
      if (typeof Mobile != 'undefined' && typeof Mobile.iframe_resize == "function") {
        Mobile.iframe_resize();
      } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.iframe_resize != 'undefined') {
        webkit.messageHandlers.iframe_resize.postMessage("event from Angular Iframe");
      } else {
        window.parent.postMessage(document.getElementsByClassName('bodyWrapper')[0].clientHeight, "*");
      }
    }, 100);
  }

  drawMachineSendMessage(event, data, object) {
    console.log("SEND", event, data);
    // window.frames[0].postMessage({"for":"usefnhjfr","data":"anything"}, '*');
  }

  drawMachineReceiveMessage(data) {
    console.log("RECEIVE", data);
  }

  addEngineClass() {
    if (typeof Mobile != 'undefined' && typeof Mobile.showAndroidToolbar == "function") {
      Mobile.showAndroidToolbar(false);
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.showIosToolbar != 'undefined') {
      webkit.messageHandlers.showIosToolbar.postMessage("false");
    } else {
      window.parent.postMessage({ "msg": "bodyClass", "class": 'iframeGamePlay' }, "*");
    }
  }

  removeEngineClass() {
    if (typeof Mobile != 'undefined' && typeof Mobile.showAndroidToolbar == "function") {
      Mobile.showAndroidToolbar(true);
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.showIosToolbar != 'undefined') {
      webkit.messageHandlers.showIosToolbar.postMessage("true");
    } else {
      window.parent.postMessage({ "msg": "bodyClass", "class": '' }, "*");
    }
  }

  updatePlayerBalance() {
    if (typeof Mobile != 'undefined' && typeof Mobile.onBalanceUpdate == "function") {
      Mobile.onBalanceUpdate();
    } else if (typeof JSInterface != 'undefined' && typeof JSInterface.updateBal == "function") {
      JSInterface.updateBal();
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.onBalanceUpdate != 'undefined') {
      webkit.messageHandlers.onBalanceUpdate.postMessage("event from Angular Iframe");
    } else if(typeof onBalanceUpdate == 'object') {
      onBalanceUpdate.postMessage("");
    } else {
      window.parent.postMessage({ "msg": "updatePlayerBalance", "class": '' }, "*");
    }
  }

  appLobbyBack() {
    if (typeof Mobile != 'undefined' && typeof Mobile.goToHome == "function") {
      Mobile.goToHome();
    } else if (typeof JSInterface != 'undefined' && typeof JSInterface.backToLobby == "function") {
      JSInterface.backToLobby();
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.goToHome != 'undefined') {
      webkit.messageHandlers.goToHome.postMessage("event from Angular Iframe");
    } else if(typeof goToHome == 'object') {
      goToHome.postMessage("");
    }
  }

  openMobileInstantUrl(url) {
    if (typeof JSInterface != 'undefined' && typeof JSInterface.loadInstantGameUrl == "function") {
      JSInterface.loadInstantGameUrl(url);
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.loadInstantGameUrl != 'undefined') {
      webkit.messageHandlers.loadInstantGameUrl.postMessage(url);
    } else if(typeof loadInstantGameUrl == 'object') {
      loadInstantGameUrl.postMessage(url);
    }
  }

  sendSaleData() {
    let userSaleData =  JSON.parse(localStorage.getItem('saleData'));
    localStorage.removeItem('saleData');
    if (typeof Mobile != 'undefined' && typeof Mobile.sendSaleData == "function") {
      Mobile.sendSaleData(JSON.stringify(userSaleData));
    } else if (typeof JSInterface != 'undefined' && typeof JSInterface.sendSaleData == "function") {
      JSInterface.sendSaleData(JSON.stringify(userSaleData));
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.sendSaleData != 'undefined') {
      webkit.messageHandlers.sendSaleData.postMessage(JSON.stringify(userSaleData));
    } else if(typeof sendSaleData == 'object') {
      sendSaleData.postMessage(JSON.stringify(userSaleData));                                                                   // It is used for flutter Mobile Application ..
    } else {
      window.parent.postMessage({ "msg": 'userSaleData', "userSaleData": JSON.stringify(userSaleData), "class": '' }, "*");
    }
  }

  openPlayerLogin() {
    if (typeof Mobile != 'undefined' && typeof Mobile.showLoginDialog == "function") {
      Mobile.showLoginDialog();
    } else if (typeof JSInterface != 'undefined' && typeof JSInterface.loginWindow == "function") {
      JSInterface.loginWindow();
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.showLoginDialog != 'undefined') {
      webkit.messageHandlers.showLoginDialog.postMessage("event from Angular Iframe");
    } else if(typeof showLoginDialog == 'object') {
      showLoginDialog.postMessage("");
    } else {
      window.parent.postMessage({ "msg": "clientLogin", "class": '' }, "*");
    }
  }

  getWindowDimensions() {
    if (typeof Mobile != 'undefined' && typeof Mobile.getWindowDimensions == "function") {
      Mobile.getWindowDimensions();
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.getWindowDimensions != 'undefined') {
      webkit.messageHandlers.getWindowDimensions.postMessage("event from Angular Iframe");
    } else if(typeof getWindowDimensions == 'object') {
      getWindowDimensions.postMessage("");
    } else {
      window.parent.postMessage({ "msg": "windowdimensions", "class": '' }, "*");
    }
  }

  redirectParent(msg) {
    if (typeof Mobile != 'undefined' && typeof Mobile.reloadGame == "function" && ["loadBackUrl", "loadDGE"].includes(msg)) {
      Mobile.reloadGame('appGameLoad');
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.reloadGame != 'undefined' && ["loadBackUrl", "loadDGE"].includes(msg)) {
      webkit.messageHandlers.redirectParent.postMessage("appGameLoad");
    } else if(typeof reloadGame == 'object') {
      reloadGame.postMessage("");
    } else {
      window.parent.postMessage({ "msg": msg, "class": '' }, "*");
    }
  }

  removeParentHash() {
    if (typeof Mobile != 'undefined' && typeof Mobile.removeParentHash == "function") {
      Mobile.removeParentHash();
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.removeParentHash != 'undefined') {
      webkit.messageHandlers.removeParentHash.postMessage("event from Angular Iframe");
    } else {
      window.parent.postMessage({ "msg": 'removeParentHash', "class": '' }, "*");
    }
  }

  setUserPurchaseData(userPurchaseData) {
    localStorage.setItem('userPurchaseTicketData', JSON.stringify(userPurchaseData));
    this.openPlayerLogin();
  }

  setUserPurchaseDataPlayAgain(userPurchaseData) {
    localStorage.setItem('userPurchaseTicketData', JSON.stringify(userPurchaseData));
  }

  getUserPurchaseData(engine) {
    let tempUser = JSON.parse(localStorage.getItem('drawGameUserInfo'));
    if (tempUser.playername != '-') {
      var userPurchaseData = JSON.parse(localStorage.getItem('userPurchaseTicketData'));
      if (userPurchaseData != null && userPurchaseData.extra.engine == engine) {
        return userPurchaseData;
      } else {
        this.removeUserPurchaseData();
        return false;
      }
    } else {
      this.removeUserPurchaseData();
      return false;
    }
  }

  removeUserPurchaseData() {
    localStorage.removeItem('userPurchaseTicketData');
  }

  notifyDrawMachine({ 'engine': engine, 'gameCode': gameCode }) {
    return this.dataservice.postData(ServerUrl.NOTIFY_DRAW_MACHINE, { 'url': Constants.GAME_DATA[engine][gameCode].draw_machine_url, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service }, { withCredentials: true }).pipe(map((response: any) => {
      return response;
    }));
  }

  getBrowserSupport() {
    if (typeof Mobile != 'undefined' && typeof Mobile.getBrowserSupport == "function") {
      Mobile.getBrowserSupport();
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.getBrowserSupport != 'undefined') {
      webkit.messageHandlers.getBrowserSupport.postMessage("event from Angular Iframe");
    } else {
      window.parent.postMessage({ "msg": "browserSupport", "class": '' }, "*");
    }
  }

  getIframeParentScrollTop() {
    if (typeof Mobile != 'undefined' && typeof Mobile.getIframeParentScrollTop == "function") {
      Mobile.getIframeParentScrollTop();
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.getIframeParentScrollTop != 'undefined') {
      webkit.messageHandlers.getIframeParentScrollTop.postMessage("event from Angular Iframe");
    } else {
      window.parent.postMessage({ "msg": "iframeParentScrollTop", "class": '' }, "*");
    }
  }

  // iframe_scroll() {
  //   let myObj: any;
  //   if (this.yScrollposition == 0) {
  //     myObj.msg = 'scrollTop';
  //   } else {
  //     myObj.msg = 'scrollPrev';
  //   }
  //   myObj.scrollHeight = this.yScrollposition;
  //   window.parent.postMessage(myObj, "*");
  // }

  // getScrollHeight() {
  //   var myObj = {msg: 'errorScrollHeight'};
  //   window.parent.postMessage(myObj, "*");
  // }

  setElementTop(elementClassName) {
    setTimeout(() => {
      let scrollTop = 0;
      let alertBox = document.getElementsByClassName(elementClassName) as HTMLCollectionOf<HTMLElement>;
      if (this.browserSupprted) {
        scrollTop = window.pageYOffset || (document.documentElement.scrollTop || document.body.parentElement.scrollTop || document.body.scrollTop);
      } else {

      }
      if (typeof alertBox[0] != 'undefined') {
        // console.log(this.browserSupprted);
        // console.log(this.iframeTopHeight);
        // console.log(window.pageYOffset);
        // console.log(document.documentElement.scrollTop);
        // console.log(document.body.parentElement.scrollTop);
        // console.log(document.body.scrollTop);
        // console.log(document.body.clientHeight);
        // console.log(window.innerHeight);
        // console.log(alertBox[0].clientHeight);
        // console.log(scrollTop);
        if (this.browserSupprted) {
          alertBox[0].style.top = ((window.innerHeight - alertBox[0].clientHeight) / 2) + 'px';
        } else {
          this.targetElement = elementClassName;
          this.getIframeParentScrollTop();
        }
      }
    }, 0);
  }

  setElementTopScroll(data) {
    let alertBox = document.getElementsByClassName(this.targetElement) as HTMLCollectionOf<HTMLElement>;
    alertBox[0].style.top = data.scrollTopHeight + ((data.clientFrameHeight - alertBox[0].clientHeight) / 2) + 'px';
  }

  getClientIP() {
    return this.dataservice.getData('http://api.ipify.org/?format=json', {}, {}).pipe(map((response: any) => {
      return response;
    }));
  }

  numberWithComma(x) {
    let decimel = Math.trunc(x);
     return decimel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}
