import { DataService } from '../_helpers/data-service-handle';
import { AppErrorHandler } from './../_helpers/app-error-handle';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ServerUrl } from '../_helpers';
import { Subject, Observable } from 'rxjs';
import { CommonService } from './common.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class AuthenticationService {

    private formatter = new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: 2
    });

    private playerDataSubject = new Subject<any>();
    private userInfoLocalSubject = new Subject<any>();

    constructor(private dataservice: DataService, private commonService: CommonService, private translate: TranslateService) { }

    subscribeplayerData(): Observable<any> {
        return this.playerDataSubject.asObservable();
    }

    subscribeuserInfoLocal(): Observable<any> {
        return this.userInfoLocalSubject.asObservable();
    }

    login(username: string, password: string) {
        return this.dataservice.postData(ServerUrl.RMS_LOGIN, { userName: username, password: password }, { withCredentials: true })
            .pipe(map((user: any) => {
                // login successful if there's a jwt token in the response
                if (user.success) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('menuData', JSON.stringify(user.data.menuData));
                    localStorage.setItem('userData', JSON.stringify(user.data.userData));
                }
                else {
                    throw new AppErrorHandler(user);
                }
                return user;
            }));
    }

    logout() {
        return this.dataservice.postData(ServerUrl.RMS_LOGOUT, {}, { withCredentials: true })
            .pipe(map(response => {
                return response;
            }));
    }

    updatePlayerData() {
        this.getUpdatedPlayerData().subscribe(response => {
            this.playerDataSubject.next(response);
        });
    }

    getUpdatedPlayerData() {
        return this.dataservice.postData(ServerUrl.RMS_USER_INFO, {}, { withCredentials: true })
            .pipe(map((user: any) => {
                if (user.success) {
                    localStorage.setItem('userData', JSON.stringify(user.data.userData));
                }
                else {
                    throw new AppErrorHandler(user);
                }
                return user.data.userData;
            }));
    }

    setUserInfo({ playerid: playerid, playername: playername, sessid: sessid, lang: lang, curr: curr, alias: alias, bal: bal, isMobileApp: isMobileApp, currDisplay: currDisplay }) {
        if (typeof playerid == 'undefined' || playerid == '') {
            playerid = '-';
        }
        if (typeof playername == 'undefined' || playername == '') {
            playername = '-';
        }
        if (typeof sessid == 'undefined' || sessid == '') {
            sessid = '-';
        }
        if (typeof lang == 'undefined' || lang == '' || lang == "-") {
            lang = 'en';
        }
        if (typeof curr == 'undefined' || curr == '' || curr == '-') {
            curr = 'USD';
        }
        if (typeof alias == 'undefined' || alias == '') {
            alias = '-';
        }
        if (typeof bal == 'undefined' || bal == '' || bal == '-') {
            bal = '0.00';
        }
        if (typeof isMobileApp == 'undefined' || isMobileApp == '' || isMobileApp == '-') {
            isMobileApp = 0;
        }
        if (typeof currDisplay == 'undefined' || currDisplay == '' || currDisplay == '-') {
            currDisplay = '-';
        }
        // let response = JSON.parse(localStorage.getItem('drawGameUserInfo'));
        // if (response == null || (playerid != '-' && playername != '-' && sessid != '-' && lang != '-' && curr != '-')) {
        return this.dataservice.postData(ServerUrl.IFRAME_USER_INT, { userId: playerid, username: playername, authToken: sessid, lang: lang, currencyCode: curr, aliasName: alias, balance: bal, isMobileApp: isMobileApp, currencyCodeDisplay: currDisplay }, { withCredentials: true })
            .pipe(map((response: any) => {
                if (response.success) {
                    localStorage.setItem('drawGameUserInfo', JSON.stringify({ playerid: playerid, playername: playername, sessid: sessid, lang: lang, curr: curr, aliasName: alias, balance: bal, isMobileApp: isMobileApp, currDisplay: currDisplay }));
                }
                return localStorage.getItem('drawGameUserInfo');
            }));
        // }
    }

    getUserInfoLocal() {
        let tempUser = JSON.parse(localStorage.getItem('drawGameUserInfo'));
        if (tempUser == null) {
            tempUser = {
                playerid: '-',
                playername: '-',
                balance: '-',
                sessid: '-',
                lang: 'en',
                curr: 'USD',
                aliasName: '-',
                isMobileApp: 0,
                currDisplay: '-',
            }
        }
        if (tempUser.playername == '-') {
            // setTimeout(() => {
                tempUser.playername = 'Hi Guest,';                  //   this.translate.instant('dge.hi_guest');
                tempUser.balance = this.formatter.format(0.00);
            // }, 1000);
        }
        this.userInfoLocalSubject.next(tempUser);
    }

    getUserInfoReturn() {
        let tempUser = JSON.parse(localStorage.getItem('drawGameUserInfo'));
        if (tempUser == null) {
            tempUser = {
                playerid: '-',
                playername: '-',
                balance: '-',
                sessid: '-',
                lang: 'en',
                curr: 'USD',
                aliasName: '-',
                isMobileApp: 0,
                currDisplay: '-',
            }
        }
        return tempUser;
    }

    updatePlayerBalance(balance) {
        let userData = JSON.parse(localStorage.getItem('drawGameUserInfo'));
        // userData.balance =  this.formatter.format(balance);
        userData.balance = balance;
        localStorage.setItem('drawGameUserInfo', JSON.stringify(userData));
        this.commonService.updatePlayerBalance();
        this.userInfoLocalSubject.next(JSON.parse(localStorage.getItem('drawGameUserInfo')));
    }

}
