import { Component, HostListener, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig, Constants } from './_helpers';
import { Router, ActivationEnd } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { CommonService } from './_services';

@Component({
  selector: '.body-outerwrapper',
  templateUrl: 'app.component.html',
})

export class AppComponent implements OnInit, OnDestroy {

  @HostListener('document:keydown', ['$event']) onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.closeModals()
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.sizeChecker();
  }

  // @HostListener('window:orientationchange', ['$event']) onOrientationChange(event) {
  //   console.log(event);
  // }

  @HostListener('window:message', ['$event']) onMessage(event) {
    if (typeof event.data.msg != "undefined" && event.data.msg == "browserSupport") {
      // this.commonService.browserSupprted = event.data.status;
      this.commonService.browserSupprted = true;  //event.data.status;                    // to put the modal height in middle of screen beacuse event.data.status is not working..                                          
      this.commonService.iframeTopHeight = event.data.topHeight;
    }
    if (typeof event.data.msg != "undefined" && event.data.msg == "iframeParentScrollTop") {
      this.commonService.setElementTopScroll(event.data);
    }
    if (typeof event.data.msg != "undefined" && event.data.msg == "orientationchange") {
      this.commonService.iframe_resize();
    }
  }

  // isIframe = false;
  isTab = false;
  tabProhibated = false;
  routeSubscription: any;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private commonService: CommonService,
    private renderer: Renderer2,
  ) {
    // if (AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].iframe) {
    //   this.isIframe = true;
    // }
    for (let i = 0; i < Constants.CLIENT_THEME.length; i++) {
      this.renderer.addClass(document.body, Constants.CLIENT_THEME[i]);
    }
    this.routeSubscription = router.events.pipe(
      filter(e => e instanceof ActivationEnd),
      first()
    ).subscribe(val => {
      let lang = '';
      let tempVar = <any>val;
      if (['ige', 'sge', 'cap', 'sge2'].includes(tempVar.snapshot._urlSegment.segments[0].path)) {
        this.tabProhibated = true;
        this.sizeChecker();
      }
      let params = JSON.parse(JSON.stringify(tempVar.snapshot.params));
      if (typeof params.lang != 'undefined' && params.lang != '-') {
        lang = params.lang;
        translate.setDefaultLang(lang);
        localStorage.setItem('lang', lang);
      } else {
        lang = localStorage.getItem('lang');
        if (lang == null || lang == '-') {
          lang = 'en';
          translate.setDefaultLang(lang);
          localStorage.setItem('lang', lang);
        } else {
          translate.setDefaultLang(lang);
        }
      }
      if (lang == 'fr') {
        this.renderer.addClass(document.body, 'ln-fr');
      }
      if (lang == 'ar') {
        this.renderer.addClass(document.documentElement, 'rtl');
        this.renderer.setAttribute(document.documentElement, 'dir', 'rtl');
      }
    });
  }

  ngOnInit() {
    this.sizeChecker();
    this.commonService.getBrowserSupport();
  }

  sizeChecker() {
    if (window.innerWidth >= Constants.TAB_WIDTH && !this.tabProhibated) {
      this.isTab = true;
    } else {
      this.isTab = false;
    }
  }

  ngOnDestroy() {
    if (typeof this.routeSubscription != 'undefined') {
      this.routeSubscription.unsubscribe();
    }
  }

  closeModals() {
    this.commonService.closeAllModals();
  }

}