import { Hardcode } from './../_helpers/hardcode-handle';
import { Injectable, OnDestroy } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { ServerUrl, AppErrorHandler, AppConfig, Constants } from '../_helpers';
import { DataService } from '../_helpers/data-service-handle';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from './common.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class SleService implements OnDestroy {
  // private subjectUserDataParent = new BehaviorSubject({});
  // private subjectUserDataChild = new BehaviorSubject({});
  // userDataObservableParent = this.subjectUserDataParent.asObservable();
  // userDataObservableChild = this.subjectUserDataChild.asObservable();
  private gameListObserver = new Subject<any>();
  private subjectOpenHelpPopup = new Subject<any>();
  private purchaseTicketResponse: any;
  isMobile = false;
  static gameTime = 0;
  static gameTimeInterval: any;
  static fetchGameCounter = 0;
  orderingTicketList = {
    gameCode: '',
    orderBy: '',
    currentTab: 1,
  };
  fromMyTickets = false;
  fromLobby = false;

  constructor(
    private dataservice: DataService,
    private alertService: AlertService,
    private commonService: CommonService,
    private translate: TranslateService, private deviceService: DeviceDetectorService
  ) {
    // let deviceInfo = this.deviceService.getDeviceInfo();
    let isDesktopDevice = this.deviceService.isDesktop();
    this.isMobile = (this.deviceService.isMobile() || this.deviceService.isTablet()) ? true : false;
  }

  ngOnDestroy(): void {
    clearInterval(SleService.gameTimeInterval);
  }

  subscribeMyTicketData(): Observable<any> {
    return this.gameListObserver.asObservable();
  }

  subscribeGameList(): Observable<any> {
    return this.gameListObserver.asObservable();
  }

  subscribeOpenHelpPopup(): Observable<any> {
    return this.subjectOpenHelpPopup.asObservable();
  }

  openHelpPopup = (status = true, gameCode) => {
    this.subjectOpenHelpPopup.next({ status: status, gameCode: gameCode });
  }

  changeDateFormat(date, joinParam = '/') {
    /* "dd-mm-YYYY HH:ii:ss" to "YYYY-mm-dd HH:ii:ss" */
    date = date.replace(/-/gi, "/").split('.')[0];
    let totalTimeArray = date.split(' ');
    if (totalTimeArray.length == 1) {
      return totalTimeArray[0].split('/').reverse().join(joinParam);
    }
    return totalTimeArray[0].split('/').reverse().join(joinParam) + ' ' + totalTimeArray[1];
  }

  getGameList = (timerType = '') => {
    let response = JSON.parse(localStorage.getItem('sleGameData'));
    if (response == null || SleService.fetchGameCounter == 0) {
      this.returnSLEDrawDataResponse(timerType).subscribe(response => {
        this.gameListObserver.next(response);
      });
    } else {
      var reset = false;
      for (var i = 0; i < response.data.sleData.gameData[0].gameTypeData.length; i++) {
        var element = response.data.sleData.gameData[0].gameTypeData[i];
        if (element.drawData != 0) {
          let drawFreezeTime = this.changeDateFormat(response.data.sleData.gameData[0].gameTypeData[i].drawData[0].ftg);
          let gameDate = new Date(drawFreezeTime);
          let diff = Math.abs(Math.abs(gameDate.getTime() - SleService.gameTime) / 1000);
          if (diff <= 0) {
            this.returnSLEDrawDataResponse(timerType).subscribe(response => {
              this.gameListObserver.next(response);
              reset = true;
            });
            break;
          }
        }
      }
      if (reset == false) {
        response.timerType = timerType;
        this.gameListObserver.next(response);
      }
    }
  }

  returnSLEDrawDataResponse(timerType = '') {
    return this.dataservice.postData(ServerUrl.SLE_FETCH_DRAWDATA, { service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        ++SleService.fetchGameCounter
        response.timerType = timerType;
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          localStorage.setItem('sleGameData', JSON.stringify(response));
          clearInterval(SleService.gameTimeInterval);
          SleService.gameTime = new Date(this.changeDateFormat(response.data.sleData.currentTime)).getTime();
          SleService.gameTimeInterval = setInterval(() => {
            SleService.gameTime += 1000;
          }, 1000);
          return response;
        } else {
          if (response.responseCode == 1000 || response.responseCode == 12345) {
            return response;
          } else {
            this.alertService.error(this.translate.instant(`error.sle.${response.responseCode}`));
          }
        }
      }));
  }

  // returnFetchDataResponse(timerType = '') {
  //   return this.dataservice.postData(ServerUrl.SLE_GET_MATCHLIST, { listType: 'drawWise', fromDate: '2019-07-09', toDate: '2020-11-23', service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
  //     .pipe(map((response: any) => {
  //       response.timerType = timerType;
  //       if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
  //         localStorage.setItem('sleGameData', JSON.stringify(response));
  //         return response;
  //       } else {
  //         this.alertService.error(response.responseMessage);
  //       }
  //     }));
  // }

  getMyTicketsList(gameId, drawId) {
    return this.dataservice.postData(ServerUrl.SLE_FETCH_TICKETS, { gameId: gameId, drawId: drawId, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        }
        return response;
      }));
  }

  // printResult(ticketData) {
  //   ticketData.service = AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service;
  //   ticketdata.isMobile = this.isMobile;
  //   if (AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].print_enable) {
  //     return this.dataservice.postData(ServerUrl.DRAW_TICKET_PRINT_RESULT, ticketData, { withCredentials: true })
  //       .pipe(map((response: any) => {
  //         if (typeof response != 'undefined' && response.success == true) {
  //           return response;
  //         } else {
  //           this.alertService.error("Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not!!");
  //         }
  //       }));
  //   } else {
  //     return new Observable((observer) => {
  //       observer.next({ success: false });
  //     });
  //   }
  // }

  ticketPurchase(ticketData) {
    ticketData.service = AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service;
    ticketData.isMobile = this.isMobile;
    return this.dataservice.postData(ServerUrl.SLE_GET_SALE, ticketData, { withCredentials: true })
      .pipe(
        tap((response: any) => {
          this.purchaseTicketResponse = response;
          this.purchaseTicketResponse.responseType = 'purchase';
        }),
        switchMap((response: any) => {
          if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
            // this.purchaseTicketResponse.data.tktData.userName = this.commonService.getPlayerData().username;
            if (AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].print_enable) {
              return this.printTicket(this.purchaseTicketResponse);
            } else {
              return new Observable((observer) => {
                observer.next(this.purchaseTicketResponse);
              });
            }
          } else {
            this.alertService.error(this.translate.instant(`error.sle.${response.responseCode}`));
            return new Observable((observer) => {
              observer.next(response);
            });
          }
        }),
        switchMap((response2: any) => {
          if (typeof response2 != 'undefined' && (typeof response2.responseCode != 'undefined' || response2.success == true)) {
            return new Observable((observer) => {
              observer.next(this.purchaseTicketResponse);
            });
          }
        }),
        switchMap((response3: any) => {
          if (typeof response3 != 'undefined' && typeof response3.data != 'undefined' && response3.data.responseCode == 0 && response3.responseType == 'cancel') {
            this.alertService.error("Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not. \nLast ticket has been cancelled!!");
            return new Observable((observer) => {
              observer.next({ success: false, responseMessage: "Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not. \nLast ticket has been cancelled!!" });
            });
          } else if (response3.responseType == 'purchase') {
            return new Observable((observer) => {
              observer.next(this.purchaseTicketResponse);
            });
          } else {
            this.alertService.error("Cannot communicate to printing application. \nPlease click on Test Print option under settings and verify printing application is working or not. \nTicket has been purchased!!");
            return new Observable((observer) => {
              observer.next(this.purchaseTicketResponse);
            });
          }
        })
      );
  }

  trackTicket(ticketNumber, txnId) {
    return this.dataservice.postData(ServerUrl.SLE_TRACK_TICKET, { ticketNumber: ticketNumber, txnId: txnId, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.sle.${response.responseCode}`));
          throw new AppErrorHandler(response);
        }
        return response;
      }));
  }

  poolSizeUpdate(drawId, gameId) {
    return this.dataservice.postData(ServerUrl.SLE_POOL_UPDATE, { gameId: gameId, drawId: drawId, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.sle.${response.responseCode}`));
          throw new AppErrorHandler(response);
        }
        return response;
      }));
  }

  printTicket(ticketData) {
    ticketData.service = AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service;
    ticketData.isMobile = this.isMobile;
    return this.dataservice.postData(ServerUrl.SLE_TICKET_PRINT_PURCHASE, ticketData, { withCredentials: true })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getSleResults({ 'gameCode': gameCode, 'startDate': fromDate, 'endDate': toDate }) {
    return this.dataservice.postData(ServerUrl.SLE_GET_RESULT, { 'gameCode': gameCode, 'startDate': fromDate, 'endDate': toDate, service: AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].service, isMobile: this.isMobile }, { withCredentials: true })
      .pipe(map((response: any) => {
        if (typeof response.data != 'undefined' && response.data.responseCode == 0) {
          return response;
        } else {
          this.alertService.error(response.responseMessage);
          throw new AppErrorHandler(response);
        }
        // return response;
      }));
  }

}
